<template>
  <div class="Profile">
    <loading-component v-if="isLoading"></loading-component>

    <v-form v-else class="Form" ref="form" v-model="valid">
      <v-container>
        <v-row>
          <v-col class="pa-0">
            <v-text-field
              :rules="requiredRule"
              outlined
              dense
              label="First Name"
              v-model="user.firstName"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              :rules="requiredRule"
              outlined
              dense
              label="Last Name"
              v-model="user.lastName"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              :rules="requiredRule"
              outlined
              dense
              label="No. Handphone"
              v-model="user.phone"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              :rules="requiredRule"
              outlined
              dense
              label="Email"
              type="email"
              v-model="user.email"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="pa-0">
            <v-text-field
              outlined
              dense
              label="Password"
              v-model="user.password"
            ></v-text-field>
          </v-col>
        </v-row> -->
      </v-container>

      <v-container>
        <v-row>
          <v-col class="pa-0">
            <v-combobox
              readonly
              outlined
              dense
              label="Kota"
              :rules="requiredRule"
              :items="cityList"
              :value="user.city"
              @input="setCity"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-combobox
              outlined
              readonly
              dense
              label="Kelurahan"
              :rules="requiredRule"
              :items="villageList"
              :value="user.village"
              @input="setVillage"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-text-field
              outlined
              dense
              label="Kode Pos"
              v-model="user.postCode"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-textarea
              :rules="requiredRule"
              outlined
              dense
              rows="1"
              no-resize
              label="Alamat"
              v-model="user.address"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-btn
              block
              depressed
              color="cyan"
              class="white--text"
              :loading="isUpdateLoading"
              @click="updateUser"
              >update</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-snackbar v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import LoadingComponent from "../../../components/dashboard/LoadingComponent.vue";

import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { formatPhone } from "../../../utils/formatter";

export default {
  components: { LoadingComponent },
  name: "Profile",
  data() {
    return {
      valid: false,
      isLoading: false,
      isUpdateLoading: false,
      snackbar: false,
      snackbarText: "",
      user: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        city: "",
        idCity: "",
        village: "",
        idVillage: "",
        postCode: "",
        district: "",
        idDistrict: "",
        address: "",
        password: "",
      },
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.user.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.user.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    updateUser() {
      const id = getProfileCookie().IDUser;
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/Profile/${id}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            NamaDepan: this.user.firstName,
            NamaBelakang: this.user.lastName,
            Email: this.user.email,
            Telp: formatPhone(this.user.phone),
            Alamat: this.user.address,
            CityIDCity: this.user.idCity,
            KecamatanIDKecamatan: this.user.idDistrict,
            KelurahanIDKelurahan: this.user.idVillage,
            KelurahanKodePos: this.user.postCode,
          },
        })
          .then((res) => {
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.fetchProfile();
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.user.idCity = city.idCity;
        this.user.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.user.idVillage = village.idKelurahan;
        this.user.idDistrict = village.idKecamatan;
        this.user.district = e;
        this.user.postCode = village.kodePos;
      }
    },
    fetchProfile() {
      const id = getProfileCookie().IDUser;
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Profile/${id}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;

          this.user.firstName = data.NamaDepan;
          this.user.lastName = data.NamaBelakang;
          this.user.phone = data.Telp;
          this.user.email = data.Email;
          this.user.city = data.City.NamaCity;
          this.user.idCity = data.CityIDCity;
          this.user.district = data.Kecamatan.NamaKecamatan;
          this.user.idDistrict = data.KecamatanIDKecamatan;
          this.user.village = data.Kelurahan.NamaKelurahan;
          this.user.idVillage = data.KelurahanIDKelurahan;
          this.user.postCode = data.Kelurahan.KodePos;
          this.user.address = data.Alamat;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.Profile {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  .Form {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .Profile {
    .Form {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}
</style>
