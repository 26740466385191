<template>
  <v-card outlined class="pa-3">
    <div class="RecipientFormComponent">
      <div class="RecipientFormComponent-Header">
        <h3>
          Recipient Information
          <!-- <v-btn
          v-if="!readOnly"
          @click="dialog = true"
          color="primary"
          elevation="2"
          x-small
          >Cari Recipient</v-btn
        >
        <v-btn
          v-if="readOnly"
          @click="reset"
          color="warning"
          class="ml-1"
          elevation="2"
          x-small
          >reset</v-btn
        > -->
        </h3>
        <hr />
      </div>

      <div class="RecipientFormComponent-Container">
        <v-container>
          <v-row>
            <v-select
              :items="via"
              @input="setService"
              return-object
              :value="service"
              item-text="Keterangan"
              item-value="value"
              label="Service"
              dense
              :rules="rules"
            ></v-select>
            <v-combobox
              label="Receiver Country"
              dense
              :rules="rules"
              :items="filterCountry"
              :value="country"
              @input="setCountry"
              item-text="CountryName"
            ></v-combobox>
          </v-row>
          <v-row>
            <v-text-field
              :rules="rule.namaPenerima"
              :value="recipientName"
              @input="setRecipientName"
              label="Nama Penerima"
              dense
            >
            </v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              :value="recipientEmail"
              @input="setRecipientEmail"
              label="Email"
              dense
              type="email"
              :rules="rules"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-combobox
              label="ID TYPE"
              dense
              :rules="
                recipientIDCountry == 209 || recipientIDCountry == 114
                  ? rules
                  : []
              "
              :items="[
                'National Identity (KTP)',
                'Tax Identication Number (NPWP)',
                'Passport',
                'Tax Collected Number',
              ]"
              @input="setIDType"
              :value="idType"
              item-text="CountryName"
            ></v-combobox>
          </v-row>

          <v-row>
            <v-text-field
              :rules="
                recipientIDCountry == 209 || recipientIDCountry == 114
                  ? rules
                  : []
              "
              :value="idNo"
              @input="setIDNo"
              label="ID. NO"
              dense
            ></v-text-field>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-text-field
              :rules="rule.nohp"
              :value="recipientContactPerson"
              @input="setRecipientContactPerson"
              label="PIC"
              dense
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              :rules="rule.nohp"
              :value="recipientPhone"
              @input="setRecipientPhone"
              label="Receiver Telephone"
              dense
            ></v-text-field>
          </v-row>

          <v-row>
            <v-text-field
              :rules="rules"
              :value="postCode"
              @input="setPostCode"
              label="Kode Pos"
              dense
              type="text"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-textarea
              :rules="rule.alamat"
              :value="address"
              @input="setAddress"
              label="Alamat"
              rows="3"
              no-resize
              @keydown.enter.prevent
            ></v-textarea>
          </v-row>
        </v-container>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Shipper</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-combobox
                  label="Nama Penerima"
                  @input="setRecipientAuto"
                  :items="recipientData"
                  item-text="Nama"
                  item-value="IDShipper"
                  dense
                  :loading="false"
                  return-object
                  :rules="rules"
                ></v-combobox>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { getUserProfile } from "../../../../services/user";
import { mapState, mapActions } from "vuex";

export default {
  name: "RecipientFormComponent",
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      rule: {
        nohp: [
          (value) => !!value || "Required",
          // (v) => (v || "").length <= 15 || "Maxsimal input 15 characters",
        ],
        namaPenerima: [
          (value) => !!value || "Required",
          // (v) => (v || "").length <= 30 || "Maxsimal input 30 characters",
        ],
        alamat: [
          (value) => !!value || "Required",
          // (v) => (v || "").length <= 85 || "Maxsimal input 85 characters",
        ],
      },
      dialog: false,
      readOnly: false,
      filterCountry: [],
    };
  },
  computed: {
    ...mapState({
      recipientData: (state) => state.shipper.recipientData,
      countryData: (state) => state.shipper.countryData,
      via: (state) => state.masterData.via,
    }),

    recipientName() {
      return this.$store.state.order.recipientData.recipientName;
    },
    recipientPhone() {
      return this.$store.state.order.recipientData.recipientPhone;
    },
    recipientEmail() {
      return this.$store.state.order.recipientData.recipientEmail;
    },
    recipientIDCountry() {
      return this.$store.state.order.recipientData.recipientIDCountry;
    },
    country() {
      return this.$store.state.order.recipientData.recipientCountryName;
    },
    recipientContactPerson() {
      return this.$store.state.order.recipientData.recipientContactPerson;
    },
    postCode() {
      return this.$store.state.order.recipientData.recipientPostCode;
    },
    address() {
      return this.$store.state.order.recipientData.recipientAddress;
    },
    newRecipient() {
      return this.$store.state.order.recipientData.newRecipient;
    },
    idType() {
      return this.$store.state.order.recipientData.idType;
    },
    idNo() {
      return this.$store.state.order.recipientData.idNo;
    },
    service() {
      return this.$store.state.order.itemData.service;
    },
  },
  created() {},
  async mounted() {
    const profile = await getUserProfile();
    this.IDHub = profile.KCX_HubIDHub;
    //get recipient data
    await this.getHubRecipient({
      IDHub: this.IDHub,
      Type: "international",
    });
    await this.getCountry();
    this.$store.commit("order/SET_RECIPIENT", {
      recipientIDHub: this.IDHub,
    });
    await this.getVia("international");
  },
  watch: {},
  methods: {
    ...mapActions({
      getHubRecipient: "shipper/getHubRecipient",
      getCountry: "shipper/getCountry",
      getVia: "masterData/getVia",
    }),
    setRecipientAuto(e) {
      if (e.Telp) {
        this.$store.commit("order/SET_RECIPIENT", { recipientName: e.Nama });
        this.setRecipientPhone(e.Telp);
        this.setRecipientEmail(e.Email);
        this.setAddress(e.Alamat);
        this.setRecipientContactPerson(e.ContactPerson);
        this.setPostCode(Number(e.KodePos));
        //set country
        if (e.IDCountry) {
          const idC = this.countryData.find(
            (id) => id.IDCountry === e.IDCountry
          );

          this.setCountry(idC);
        }
      } else {
        this.$store.commit("order/SET_RECIPIENT", { recipientName: e });
      }
      this.dialog = false;
      this.$store.commit("order/SET_RECIPIENT", { newRecipient: false });
      this.readOnly = true;
      // this.$store.commit("order/SET_RECIPIENT", {
      //   recipientIDHub: this.IDHub,
      // });
    },
    setRecipientName(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientName: e });
    },
    setListPrice(e) {
      this.$store.commit("order/SET_ITEM", { listPrice: e });
    },
    setService(e) {
      this.$store.commit("order/SET_ITEM", { service: e.Keterangan });
      this.$store.commit("order/SET_ITEM", { idvia: e.IDVia });
      this.filterCountry = [];
      this.$store.state.order.recipientData.recipientCountryName = "";
      this.$store.state.order.recipientData.recipientIDCountry = "";
      this.setListPrice([]);
      if (e.Keterangan == "PREMIUM") {
        this.filterCountry = this.countryData;
      } else if (e.Keterangan == "ECO") {
        this.countryData.find((country) => {
          if (
            country.IDCountry == 190 ||
            country.IDCountry == 114 ||
            country.IDCountry == 132 ||
            country.IDCountry == 239 ||
            country.IDCountry == 209
          ) {
            this.filterCountry.push(country);
          }
        });
      } else {
        this.filterCountry = [];
      }
    },
    setRecipientContactPerson(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientContactPerson: e });
    },
    setRecipientPhone(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPhone: e });
    },
    setRecipientEmail(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientEmail: e });
    },
    setCountry(e) {
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
        listPrice: [],
      });
      this.$store.commit("order/SET_RECIPIENT", {
        recipientIDCountry: e.IDCountry,
        recipientCountryName: e.CountryName,
      });
    },
    setPostCode(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPostCode: e });
    },
    setAddress(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientAddress: e });
    },
    setIDType(e) {
      this.$store.commit("order/SET_RECIPIENT", { idType: e });
    },
    setIDNo(e) {
      this.$store.commit("order/SET_RECIPIENT", { idNo: e });
    },
    reset() {
      this.readOnly = false;
      this.$store.commit("order/SET_RECIPIENT", { newRecipient: true });

      this.$store.commit("order/CLEAR_RECIPIENT");
    },
  },
};
</script>

<style lang="scss" scoped>
.RecipientFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
