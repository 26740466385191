<template>
  <v-card outlined class="pa-3">
    <div class="ShipperFormComponent">
      <div class="ShipperFormComponent-Header">
        <h3>
          Shipper Information
          <!-- <v-btn
          v-if="!readOnly"
          @click="dialog = true"
          color="primary"
          elevation="2"
          x-small
          >Cari Shipper</v-btn
        >
        <v-btn
          v-if="readOnly"
          @click="reset"
          color="warning"
          class="ml-1"
          elevation="2"
          x-small
          >reset</v-btn
        > -->
        </h3>
        <hr />
      </div>

      <div class="ShipperFormComponent-Container">
        <v-container>
          <v-row>
            <v-text-field
              :rules="rules"
              :value="shipperName"
              @input="setShipperName"
              label="Nama Shipper"
              dense
              :readonly="readOnly"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-text-field
              :rules="rules"
              :value="shipperPhone"
              @input="setShipperPhone"
              label="No. HP"
              dense
              :readonly="readOnly"
              type="number"
            ></v-text-field>
          </v-row>

          <!-- <v-row>
          <v-text-field
            :value="shipperEmail"
            @input="setShipperEmail"
            label="Email"
            dense
            :readonly="readOnly"
          ></v-text-field>
        </v-row> -->

          <v-row>
            <v-textarea
              @keydown.enter.prevent
              :rules="rules"
              :value="address"
              @input="setAddress"
              rows="3"
              no-resize
              label="Alamat"
              :readonly="readOnly"
            ></v-textarea>
          </v-row>
        </v-container>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Shipper</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-combobox
                  label="Nama Pengirim"
                  @input="setShipperAuto"
                  :items="shipperData"
                  item-text="Nama"
                  item-value="IDShipper"
                  dense
                  :loading="false"
                  return-object
                  :rules="rules"
                ></v-combobox>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import cityData from "../../../../data/city.json";
import villageData from "../../../../data/kelurahan.json";
import { getUserProfile } from "../../../../services/user";
import { mapState, mapActions } from "vuex";

export default {
  name: "ShipperFormComponent",
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      IDHub: "",
      dialog: false,
      readOnly: false,
    };
  },
  computed: {
    ...mapState({
      shipperData: (state) => state.shipper.shipperData,
    }),
    shipperName() {
      return this.$store.state.order.shipperData.shipperName;
    },
    shipperPhone() {
      return this.$store.state.order.shipperData.shipperPhone;
    },
    shipperEmail() {
      return this.$store.state.order.shipperData.shipperEmail;
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    city() {
      return this.$store.state.order.shipperData.shipperCity;
    },
    cityId() {
      return this.$store.state.order.shipperData.shipperCityId;
    },
    villageList() {
      let villageArray = [];
      if (this.cityId) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.cityId;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
    village() {
      return this.$store.state.order.shipperData.shipperKelurahan;
    },
    villageId() {
      return this.$store.state.order.shipperData.shipperKelurahanId;
    },
    postCode() {
      return this.$store.state.order.shipperData.shipperPostCode;
    },
    address() {
      return this.$store.state.order.shipperData.shipperAddress;
    },
    newShipper() {
      return this.$store.state.order.shipperData.newShipper;
    },
  },
  created() {},
  async mounted() {
    const profile = await getUserProfile();
    this.IDHub = profile.KCX_HubIDHub;

    this.$store.commit("order/SET_SHIPPER", {
      shipperCityId: profile.CityIDCity,
      shipperCity: profile.City.NamaCity,
      shipperKelurahanId: profile.KelurahanIDKelurahan,
      shipperKecamatanId: profile.KecamatanIDKecamatan,
      shipperKelurahan: profile.Kelurahan.NamaKelurahan,
      shipperKecamatan: profile.Kecamatan.NamaKecamatan,
      shipperPostCode: profile.KelurahanKodePos,
      // shipperAddress: profile.Alamat,
    });
    //get shipper data
    await this.getHubShipper(this.IDHub);
    this.$store.commit("order/SET_SHIPPER", {
      shipperIDHub: this.IDHub,
    });
  },
  watch: {},
  methods: {
    ...mapActions({
      getHubShipper: "shipper/getHubShipper",
    }),
    async setShipperAuto(e) {
      // this.$store.commit("order/SET_SHIPPER", { shipperName: e });
      if (e.Telp) {
        this.$store.commit("order/SET_SHIPPER", { shipperPhone: e.Telp });
        this.$store.commit("order/SET_SHIPPER", { shipperEmail: e.Email });
        this.$store.commit("order/SET_SHIPPER", { shipperAddress: e.Alamat });
        this.$store.commit("order/SET_SHIPPER", { shipperName: e.Nama });
      } else {
        this.$store.commit("order/SET_SHIPPER", { shipperName: e });
      }
      this.dialog = false;
      this.$store.commit("order/SET_SHIPPER", { newShipper: false });

      this.readOnly = true;
      // this.$store.commit("order/SET_SHIPPER", {
      //   shipperIDHub: this.IDHub,
      // });
    },
    setShipperName(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperName: e });
    },
    setShipperPhone(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperPhone: e });
    },
    setShipperEmail(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperEmail: e });
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.$store.commit("order/SET_SHIPPER", {
          shipperCityId: city.idCity,
          shipperCity: city.city,
        });
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtName = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtName
      );

      if (village) {
        this.$store.commit("order/SET_SHIPPER", {
          shipperKelurahanId: village.idKelurahan,
          shipperKecamatanId: village.idKecamatan,
          shipperKelurahan: village.kelurahan,
          shipperKecamatan: village.kecamatan,
          shipperPostCode: village.kodePos,
        });
      }
    },
    setPostCode(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperPostCode: e });
    },
    setAddress(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperAddress: e });
    },
    reset() {
      this.readOnly = false;
      this.$store.commit("order/SET_SHIPPER", { newShipper: true });
      this.$store.commit("order/CLEAR_SHIPPER");
    },
  },
};
</script>

<style lang="scss" scoped>
.ShipperFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
