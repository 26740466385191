import axios from "axios";

import { baseUrl } from "../constant/url";
import { getTokenCookie } from "../utils/cookies";

export async function cancelOrder(wbNumber, status) {
  console.log(wbNumber);
  const { data: resp } = await axios({
    method: "put",
    url: `${baseUrl}/HubInboundOrders/${wbNumber}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
      status: status,
    },
  });
  return resp.data;
}
