<template>
  <div class="DialogUploadTFComponent">
    <v-dialog v-model="dialogUploadTF" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="resetForm()"
          depressed
          color="warning"
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon dark> mdi-upload </v-icon> Upload Bukti TF
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Upload Image Bukti Transfer</v-card-title>
        <br />

        <v-card-text>
          <v-form ref="form">
            <v-file-input
              ref="fileupload"
              accept="image/png, image/jpeg, image/jpg"
              label="Upload Bukti Transfer"
              @change="onFileChange($event)"
              :clearable="true"
              v-model="fileUpload"
              messages="Maksimal Image Size 2MB"
            ></v-file-input>
            <div v-if="fileUpload">
              <v-img height="100%" :src="imgPreview" />
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogUploadTF = false">
            cancel
          </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="uploadTF"
            :disabled="loadingButton"
          >
            <v-progress-circular
              indeterminate
              color="white"
              v-if="loadingButton"
            ></v-progress-circular
            ><span v-else>Upload</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogUploadTFComponent",
  props: {
    loadRiwayatTopup: { type: Function },
    loadBalance: { type: Function },
    itemData: { type: Object },
    startDate: { type: String },
    endDate: { type: String },
  },
  data() {
    return {
      loadingButton: false,
      dialogUploadTF: false,
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      snackbar: false,
      snackbarText: "",
      fileinput: "",
      fileUpload: null,
      imgPreview: null,
      colorSnackbar: "red",
    };
  },

  methods: {
    resetForm() {
      this.fileUpload = null;
    },
    async uploadTF() {
      try {
        if (this.fileUpload == null) {
          this.snackbar = true;
          this.colorSnackbar = "red";
          this.snackbarText = "Silahkan Upload Bukti Terlebih Dahulu";
          return;
        }

        this.loadingButton = true;
        let formData = new FormData();
        formData.append("buktiTF", this.fileUpload);
        formData.append("IDTopup", this.itemData.IDTopupHub);

        axios({
          url: `${baseUrl}/deposit/uploadTF`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: formData,
        })
          .then((res) => {
            this.loadRiwayatTopup(this.startDate, this.endDate);
            this.fileUpload = null;
            this.imgPreview = null;
            this.snackbar = true;
            this.colorSnackbar = "green";
            this.snackbarText = res.data.message.info[0];
            this.dialogUploadTF = false;
            this.loadingButton = false;
          })
          .catch((error) => {
            console.log(error);
            this.snackbar = true;
            this.colorSnackbar = "red";
            this.snackbarText =
              "Terjadi Masalah Saat Upload. Silahkan Coba Kembali. Pastikan ukuran file dibawah Maksimal";
            this.loadingButton = false;
          });
      } catch (error) {
        this.snackbar = true;
        this.colorSnackbar = "red";
        this.snackbarText = error;
        this.loadingButton = false;
      }
    },
    onFileChange(e) {
      if (e.type.match("image/*")) {
        this.fileUpload = e;
        this.imgPreview = URL.createObjectURL(e);
      } else {
        this.snackbar = true;
        this.snackbarText = "File Harus Image";
        this.fileUpload = null;
        this.imgPreview = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
