import { render, staticRenderFns } from "./DropDownRecapComponent.vue?vue&type=template&id=56b523a3&scoped=true"
import script from "./DropDownRecapComponent.vue?vue&type=script&lang=js"
export * from "./DropDownRecapComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b523a3",
  null
  
)

export default component.exports