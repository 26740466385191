<template>
  <div class="ItemFormComponent">
    <div class="ItemFormComponent-Header">
      <h3>Package Information</h3>
      <hr />
    </div>

    <div class="ItemFormComponent-Container">
      <v-row>
        <v-col cols="7">
          <label> Package Type</label>
          <v-card class="mt-5 mb-8">
            <v-tabs
              class="pt-5"
              v-model="tab"
              centered
              icons-and-text
              height="50px"
              @change="setKategory"
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab href="#Umum">
                <small>Produk Umum</small>
                <v-icon>mdi-archive</v-icon>
              </v-tab>

              <v-tab href="#Elektronik">
                <small>Elektornik</small>
                <v-icon>mdi-lightning-bolt</v-icon>
              </v-tab>

              <v-tab v-if="service != 'ECO'" href="#Dokumen">
                <small>Dokumen</small>
                <v-icon>mdi-file-document</v-icon>
              </v-tab>

              <v-tab href="#Makanan">
                <small>Makanan</small>
                <v-icon>mdi-food-fork-drink</v-icon>
              </v-tab>

              <v-tab href="#Kosmestik">
                <small>Kosmetik</small>
                <v-icon>mdi-auto-fix</v-icon>
              </v-tab>

              <v-tab href="#Kopi">
                <small>Kopi</small>
                <v-icon>mdi-coffee</v-icon>
              </v-tab>

              <v-tab href="#Medicine">
                <small> Obat & Herbal</small>
                <v-icon>mdi-medical-bag</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="i in kategoryTab" :key="i" :value="i">
                <v-card flat class="pa-10">
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-if="tab != 'Dokumen'"
                        label="Kategori"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        :items="listKategory"
                        item-text="Name"
                        :value="kategoryPacket"
                        @input="setKategoryPacket"
                        v-model="packetKategory"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Length (cm)"
                        dense
                        :rules="rules"
                        v-model="colly.length"
                        @input="setColly"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Width (cm)"
                        dense
                        :rules="rules"
                        v-model="colly.width"
                        @input="setColly"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Height (cm)"
                        dense
                        :rules="rules"
                        v-model="colly.height"
                        @input="setColly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-text-field
            v-model="totalItem"
            class="mt-4"
            type="number"
            label="Total Item"
            dense
            :rules="rules"
            @input="countDetailItems"
            :min="1"
          ></v-text-field>

          <v-textarea
            :rules="rule.descriptionRule"
            label="keterangan"
            rows="2"
            no-resize
            :value="description"
            @input="setDescription"
          ></v-textarea>

          <v-textarea
            :rules="rule.descriptionRule"
            label="Instruksi Khusus"
            rows="2"
            no-resize
            :value="instruction"
            @input="setInstruction"
          ></v-textarea>
          <v-row>
            <v-col>
              <v-combobox
                class="mt-4"
                label="Item Value"
                dense
                :rules="rules"
                :items="['IDR']"
                :value="itemValue"
                :input="setItemValue"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" sm="12">
              <v-card outlined class="pa-3">
                <h4>Additional Price</h4>
                <hr />
                <v-btn
                  color="orange"
                  class="white--text"
                  @click="addAdditionalCost"
                  block
                  small
                  ><v-icon dark> mdi-plus </v-icon> Add</v-btn
                >
                <hr />

                <div v-for="(i, index) in additionalCost" :key="index">
                  <v-card class="pa-2 mb-3">
                    <v-combobox
                      class="mt-5"
                      label="Type"
                      dense
                      :items="[
                        'RESTRICTED DESTINATION',
                        'OVERSIZED',
                        'OVERWEIGHT',
                        'NON STACKABLE PALLET',
                        'ELEVATED RISK',
                        'INSURANCE DOC',
                        'REMOTE AREA',
                        'EXTENDED AREA',
                        'OVER MAXIMUM LIMIT FEE',
                        'PENANGANAN TAMBAHAN',
                        'EXPORT DECLARATION SURCHARGE',
                        'PENANAGAN PALET',
                        'PEB SINGLE',
                        'PACKING 1',
                        'PACKING 2',
                        'PACKING VOLUME',
                        'WOODEN PALLET',
                        'FORKLIFT',
                        'ADDRESS CORRECTION',
                        'OTHER',
                      ]"
                      v-model="i.typeAdditional"
                    ></v-combobox>
                    <v-text-field
                      v-model="i.costAdditional"
                      class="mt-4"
                      type="number"
                      label="Price"
                      dense
                      :rules="rulesCost"
                      @input="totalAddCost"
                    ></v-text-field>

                    <div v-if="index != 0" class="mt-2 mb-2">
                      <v-btn
                        color="red"
                        class="white--text mb-2"
                        @click="removeAdditionalCost(index)"
                        block
                        small
                        ><v-icon dark> mdi-minus </v-icon> Delete</v-btn
                      >
                    </div>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" sm="12">
              <v-card outlined class="pa-3">
                <h4>Surcharge</h4>
                <hr />
                <v-combobox
                  label="Type Of Surcharge"
                  dense
                  :items="[
                    'GENERAL CARGO',
                    'VALUABLE GOODS',
                    'DANGEROUS GOODS',
                    'SENSITIVE ITEM - STANDART',
                    'SENSITIVE ITEM - GLOBAL',
                    'HEAVY CARGO (70KG - 250KG)',
                  ]"
                  @input="setNameSurcharge"
                  :value="nameSurcharge"
                ></v-combobox>
                <v-text-field
                  :value="costSurcharge"
                  class="mt-4"
                  type="number"
                  label="Cost"
                  dense
                  :rules="rulesCost"
                  @input="setCostSurcharge"
                ></v-text-field>
                <v-textarea
                  label="Good Description"
                  rows="2"
                  no-resize
                  :value="descSurcharge"
                  @input="setDescSurcharge"
                ></v-textarea>
              </v-card>
            </v-col>

            <v-col md="12" sm="12">
              <v-img
                :src="globals_reg"
                width="100%"
                v-if="service == 'ECO' || service == 'EXPRESS'"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-card
            outlined
            shaped
            class="pa-4 mt-2"
            v-if="itemDetail.length > 0"
          >
            <p
              style="color: white; font-size: 12px; padding: 5px"
              class="hg-orange orange"
              v-if="colly.weight > 69.5"
            >
              Pengiriman 1 Koli di atas 69,50kg akan di kenakan Biaya Overweight
              Surcharge
            </p>

            <v-row v-for="(i, index) in itemDetail" :key="index">
              <v-col cols="12">
                <v-chip close-icon="mdi-close-outline" color="orange" outlined
                  >Items {{ index + 1 }}</v-chip
                >
              </v-col>
              <v-col cols="8">
                <v-textarea
                  label="Item Description"
                  rows="2"
                  no-resize
                  v-model="i.description"
                  :rules="rules"
                ></v-textarea>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="i.weight"
                  class="mt-4"
                  type="number"
                  label="Item Weight (Kg)"
                  dense
                  :rules="rules"
                  @input="detailWeight"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="i.price"
                  class="mt-4"
                  type="number"
                  label="Item Price"
                  dense
                  :rules="rules"
                  @input="detailPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="i.hscode"
                  class="mt-4"
                  type="number"
                  label="Item HS Code"
                  dense
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="i.pieces"
                  class="mt-4"
                  type="number"
                  label="Item Pieces"
                  dense
                  :rules="rules"
                ></v-text-field>
              </v-col>

              <v-divider></v-divider>
            </v-row>
          </v-card>
          <v-col>
            <v-text-field
              label="Total Berat (Kg) "
              dense
              :value="colly.weight"
              @input="setColly"
              type="number"
              v-model="colly.weight"
              class="mt-5"
              readonly
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              type="number"
              label="Nilai Barang"
              class="mt-4"
              dense
              :value="estimateValue"
              v-model="estimateValue"
              @input="setEstimateValue"
              readonly
              :rules="insurance ? rules : undefined"
            ></v-text-field>
          </v-col>

          <hr />
          <v-col>
            <v-text-field
              readonly
              type="number"
              label="Ongkir 3PL"
              class="mt-4"
              dense
              :value="price3PL"
              @input="setprice3PL"
              :rules="insurance ? rules : undefined"
            ></v-text-field>
          </v-col>
          <v-row>
            <v-col class="pa-5 ma-0">
              <v-checkbox
                label="Asuransi"
                v-model="checkInsurance"
                @change="setInsurance"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <div>
              <v-btn
                depressed
                color="cyan"
                width="200"
                class="ml-2 white--text"
                @click="checkPrice"
              >
                <v-progress-circular
                  indeterminate
                  color="white"
                  v-if="loadingCheck"
                ></v-progress-circular>
                <span v-else>Check Price</span></v-btn
              >
            </div></v-row
          >

          <v-row>
            <v-col>
              <div class="CreateOrder-Summary">
                <br />
                <div v-if="listPrice.length > 0">
                  Price List :
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Service</th>
                          <th class="text-left">Price</th>
                          <th class="text-left">Etd</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in listPrice"
                          :key="index"
                          @click="setPrice(item, index)"
                          :style="
                            choice === index ? 'background-color:orange' : ''
                          "
                        >
                          <td>
                            <v-chip
                              class="ma-2"
                              color="green"
                              small
                              text-color="white"
                            >
                              {{ item.service }} - {{ item.category }}
                            </v-chip>
                          </td>
                          <td>
                            <strong>{{ convertToRp(item.price) }}</strong>
                          </td>
                          <td>{{ item.etd }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>

                <br />
                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Total Berat</v-col>
                  <v-col cols="6" class="pa-0">{{ mostWeight }} Kg</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Total Biaya Packing</v-col>
                  <v-col cols="6" class="pa-0">{{
                    toRupiah(packingPrice)
                  }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Biaya Kirim</v-col>
                  <v-col cols="6" class="pa-0">{{ toRupiah(sendPrice) }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Diskon</v-col>
                  <v-col cols="6" class="pa-0">{{ toRupiah(discount) }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0" v-if="addCostTotal > 0">
                  <v-col cols="6" class="pa-0">Additional Cost</v-col>
                  <v-col cols="6" class="pa-0">{{
                    toRupiah(addCostTotal)
                  }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0" v-if="costSurcharge > 0">
                  <v-col cols="6" class="pa-0">Cost Surcharge</v-col>
                  <v-col cols="6" class="pa-0">{{
                    toRupiah(costSurcharge)
                  }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">PPN</v-col>
                  <v-col cols="6" class="pa-0">{{ toRupiah(ppn) }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Price3PL</v-col>
                  <v-col cols="6" class="pa-0">{{ toRupiah(price3PL) }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0">Asuransi</v-col>
                  <v-col cols="6" class="pa-0">{{ toRupiah(insurance) }}</v-col>
                </v-row>

                <v-row class="d-flex justify-end ma-0">
                  <v-col cols="6" class="pa-0"
                    ><strong>Total Biaya</strong></v-col
                  >
                  <v-col cols="6" class="pa-0">
                    <strong>{{ toRupiah(totalBiaya) }}</strong>
                  </v-col>
                </v-row>
              </div>

              <div class="CreateOrder-Action mt-5">
                <v-row>
                  <v-col cols="6"
                    ><v-btn outlined color="cyan" width="100%" @click="clear"
                      >clear</v-btn
                    ></v-col
                  >

                  <v-col cols="6">
                    <v-btn
                      :disabled="sendPrice > 0 ? false : true"
                      :loading="isLoading"
                      depressed
                      width="100%"
                      color="cyan"
                      class="ml-2 white--text"
                      @click="book"
                      >book</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-badge
                      color="error"
                      :content="orderList.length"
                      overlap
                      v-if="orderList.length > 0"
                    >
                      <v-btn
                        @click="sheet = true"
                        class="white--text"
                        color="primary"
                        depressed
                        block
                      >
                        List Order
                      </v-btn>
                    </v-badge>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="text-center">
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center" height="400px">
          <div class="text-center mt-5 pa-15" v-if="loadingListOrder">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-simple-table fixed-header height="250px" v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Shipper</th>
                  <th class="text-left">Recipient</th>
                  <th class="text-left">Koli</th>
                  <th class="text-left">Total Berat</th>
                  <th class="text-left">Destination</th>
                  <th class="text-right">Total Biaya</th>

                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orderList" :key="index">
                  <td class="text-left">{{ item.shipper.Nama }}</td>
                  <td class="text-left">{{ item.recipient.Nama }}</td>
                  <td class="text-left">
                    {{ item.item.collies.JumlahKoli }}
                  </td>
                  <td class="text-left">{{ item.item.TotalBerat }}</td>
                  <td class="text-left">{{ item.recipient.CountryName }}</td>
                  <td class="text-right">
                    {{
                      item.item.TotalBiaya ? toRupiah(item.item.TotalBiaya) : 0
                    }}
                  </td>

                  <td>
                    <v-btn icon color="pink" @click="deleteOrder(item)">
                      <v-progress-circular
                        indeterminate
                        color="cyan"
                        v-if="isLoadingDelete"
                      ></v-progress-circular>
                      <v-icon v-else>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right">Total Biaya</td>
                  <td class="text-right">
                    <strong>{{ toRupiah(sumBiaya(orderList.item)) }}</strong>
                  </td>
                  <td colspan="1">&nbsp;</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-bottom-navigation absolute horizontal grow>
            <v-btn
              color="deep-red accent-4"
              text
              elevation="2"
              rounded
              @click="sheet = false"
            >
              <span>Close</span>

              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              color="deep-info accent-4"
              text
              elevation="2"
              rounded
              :disabled="isLoading"
              @click="done"
            >
              <v-progress-circular
                indeterminate
                color="cyan"
                v-if="isLoading"
              ></v-progress-circular>
              <span v-else>Buat Order</span>

              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../../constant/url";
import kategory from "../../../../data/kategory.json";
import { getProfileCookie, getTokenCookie } from "../../../../utils/cookies";
import { calculateVolumetricWeight } from "../../../../utils/calculationInter";
import { mapState } from "vuex";
import { INSURANCE_RATE_INTERNATIONAL } from "../../../../constant/insurance";
import { formatToRupiah } from "../../../../utils/formatter";
import globals_reg from "../../../../assets/images/globals_reg.jpeg";
import sentralOrigin from "../../../../data/originSentral.json";
import cityData from "../../../../data/city.json";

export default {
  name: "ItemFormComponent",
  props: {
    book: { type: Function },
    done: { type: Function },
  },
  mounted() {
    const item = this.$store.state.order.itemData;
    if (item.orderType) {
      this.packetKategory = this.$store.state.order.kategoryPacket;
      this.colly = item.collies;
      this.totalItem = item.itemsDetail.length;
      this.countDetailItemsStore(this.totalItem, item.itemsDetail);
      this.detailWeight();
    }
    this.fetchTempOrder();
  },
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      rule: {
        descriptionRule: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 60 || "Maxsimal input 60 characters",
        ],
      },
      rulesCost: [(v) => v == 0 || v > 0 || "Minimal 1 Rupiah"],
      valid: false,
      positiveNumberRule: [(value) => value > 0 || "Add item"],
      modaOption: ["Darat", "Laut", "Udara"],
      tab: null,
      totalItem: 0,
      itemDetail: [],
      checkInsurance: true,
      kategoryTab: [
        "Umum",
        "Elektronik",
        "Dokumen",
        "Makanan",
        "Kosmestik",
        "Kopi",
        "Medicine",
      ],
      listKategory: [],
      colly: {
        numberColly: 1,
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        volumeWeight: 0,
        isPacking: false,
        packingPrice: 0,
        mostWeight: 0,
        totalPrice: 0,
      },
      totalWeight: 0,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      loadingCheck: false,
      // service: "PREMIUM",
      choice: null,
      globals_reg,
      kategory: "",
      sheet: false,
      loadingListOrder: false,
      orderList: [],
      colorSnackbar: "cyan",
      isLoadingDelete: false,
      packetKategory: [],
      cityName: "",
    };
  },
  computed: {
    ...mapState({
      itemValue: (state) => state.order.itemData.itemValue,
      pebHardCopy: (state) => state.order.itemData.pebHardCopy,
      paymentType: (state) => state.order.itemData.paymentType,
      kategoryPacket: (state) => state.order.itemData.kategoryPacket,
    }),
    description() {
      return this.$store.state.order.itemData.description;
    },
    instruction() {
      return this.$store.state.order.itemData.instruction;
    },
    discount() {
      return this.$store.state.order.itemData.discount;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    estimateValue() {
      return this.$store.state.order.itemData.estimateValue;
    },
    isInsurance() {
      return this.$store.state.order.itemData.isInsurance;
    },
    totalColly() {
      const collies = this.$store.state.order.itemData.collies;
      return collies.length;
    },
    collyDetail() {
      return this.$store.state.order.itemData.collies;
    },
    itemsDetail() {
      return this.$store.state.order.itemData.itemsDetail;
    },
    minEtd() {
      return this.$store.state.order.itemData.minEtd;
    },
    maxEtd() {
      return this.$store.state.order.itemData.maxEtd;
    },
    collies() {
      const collies = this.$store.state.order.itemData.collies;
      return collies;
    },
    mostWeight() {
      return this.$store.state.order.itemData.totalWeight;
    },
    packingPrice() {
      return 0;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
    insurance() {
      return this.$store.state.order.itemData.insurance;
    },
    addCostTotal() {
      return this.$store.state.order.itemData.addCostTotal;
    },
    nameSurcharge() {
      return this.$store.state.order.itemData.nameSurcharge;
    },
    costSurcharge() {
      return this.$store.state.order.itemData.costSurcharge;
    },
    descSurcharge() {
      return this.$store.state.order.itemData.descSurcharge;
    },
    additionalCost() {
      return this.$store.state.order.itemData.additionalCost;
    },
    listPrice() {
      return this.$store.state.order.itemData.listPrice;
    },
    service() {
      return this.$store.state.order.itemData.service;
    },
    ppn() {
      return 0;
    },
    totalPrice() {
      const total = this.packingPrice + this.sendPrice - this.discount;
      return total;
    },
    totalBiaya() {
      let totalBiaya =
        this.totalPrice +
        this.insurance +
        this.ppn +
        Number(this.price3PL) +
        Number(this.costSurcharge) +
        Number(this.addCostTotal);
      return isNaN(totalBiaya) ? 0 : totalBiaya;
    },
    price3PL() {
      return Number(this.$store.state.order.itemData.price3PL);
    },
  },
  methods: {
    setChoice(id) {
      this.choice = id;
    },
    setNameSurcharge(e) {
      this.$store.commit("order/SET_ITEM", { nameSurcharge: e });
    },
    setCostSurcharge(e) {
      this.$store.commit("order/SET_ITEM", { costSurcharge: e });
    },
    setDescSurcharge(e) {
      this.$store.commit("order/SET_ITEM", { descSurcharge: e });
    },
    setListPrice(e) {
      this.$store.commit("order/SET_ITEM", { listPrice: e });
    },
    setAddCostTotal(e) {
      this.$store.commit("order/SET_ITEM", { addCostTotal: e });
    },
    setWeight(e) {
      this.$store.commit("order/SET_ITEM", { weight: e });
    },
    setDescription(e) {
      this.$store.commit("order/SET_ITEM", { description: e });
    },
    setInstruction(e) {
      this.$store.commit("order/SET_ITEM", { instruction: e });
    },
    setDiscount(e) {
      this.$store.commit("order/SET_ITEM", { discount: e });
    },
    setTax(e) {
      this.$store.commit("order/SET_ITEM", { tax: e });
    },
    setEstimateValue(e) {
      this.$store.commit("order/SET_ITEM", { estimateValue: e });
    },
    setprice3PL(e) {
      this.$store.commit("order/SET_ITEM", { price3PL: Number(e) });
    },
    setInsurance(e) {
      this.$store.commit("order/SET_ITEM", { isInsurance: e });

      if (this.$store.state.order.itemData.isInsurance) {
        const estimateValue = parseFloat(
          this.$store.state.order.itemData.estimateValue
        );
        this.$store.commit("order/SET_ITEM", {
          insurance: estimateValue * INSURANCE_RATE_INTERNATIONAL,
          isInsurance: this.checkInsurance,
        });

        return estimateValue * INSURANCE_RATE_INTERNATIONAL;
      }
      this.$store.commit("order/SET_ITEM", {
        insurance: 0,
        isInsurance: this.checkInsurance,
      });
    },
    setItemValue(e) {
      this.$store.commit("order/SET_ITEM", { itemValue: e });
    },
    setItemDetails(e) {
      this.$store.commit("order/SET_ITEM", { itemsDetail: e });
    },
    setKategory(e) {
      this.$store.commit("order/SET_ITEM", { kategoryPacket: [] });
      this.listKategory = [];

      let list = kategory.filter((kategory) => kategory.Kategory == e);
      this.listKategory = list;
    },
    setKategoryPacket(e) {
      this.$store.commit("order/SET_ITEM", { kategoryPacket: e });
    },
    totalAddCost() {
      let total =
        this.additionalCost.reduce(
          (a, b) => Number(a) + Number(b.costAdditional),
          0
        ) || 0;
      this.setAddCostTotal(total);
    },
    detailWeight() {
      let total =
        this.itemDetail.reduce((a, b) => Number(a) + Number(b.weight), 0) || 0;
      this.colly.weight = total.toFixed(2);
    },
    detailPrice() {
      let totalPrice =
        this.itemDetail.reduce((a, b) => Number(a) + Number(b.price), 0) || 0;
      this.$store.commit("order/SET_ITEM", {
        estimateValue: totalPrice.toFixed(0),
      });

      this.setInsurance(totalPrice.toFixed(0));
    },
    setColly() {
      const volumeWeight = calculateVolumetricWeight(
        this.colly.length,
        this.colly.width,
        this.colly.height
      );
      const weight = Number(this.colly.weight);
      const mostWeight = volumeWeight > weight ? volumeWeight : weight;

      const collies = {
        ...this.$store.state.order.itemData.collies,
        numberColly: Number(this.colly.numberColly),
        weight,
        length: Number(this.colly.length),
        width: Number(this.colly.width),
        height: Number(this.colly.height),
        volumeWeight,
        isPacking: false,
        packingPrice: 0,
        mostWeight,
        totalPrice: 0,
      };
      this.totalWeight = mostWeight;
      this.$store.commit("order/SET_ITEM", { collies });
    },
    async checkPrice() {
      try {
        const cityName = getProfileCookie().NamaCity;
        const city = cityData.find(({ city }) => city == cityName);
        const orgCity =
          cityName.search("Jakarta") > 0
            ? "Jakarta"
            : cityName
                .replace("Kabupaten", "")
                .replace("Kota", "")
                .replace("Selatan", "")
                .replace("Timur", "")
                .replace("Utara", "")
                .replace("Barat", "")
                .replace("Tenggara", "")
                .replace("Daya", "")
                .replace("Kota Adm.", "")
                .replace("Tengah", "");
        const originSentral = sentralOrigin.find(
          ({ OriginName }) =>
            OriginName.toLowerCase().trim() == orgCity.toLowerCase().trim()
        );
        if (city) {
          // this.kodeCityOrigin = city.KodeCity;
          this.cityName = originSentral ? originSentral.OriginDestCode : "";
        } else {
          this.loadingCheck = false;
          this.snackbar = true;
          this.snackbarText = "Kota Origin tidak ditemukan";
          return;
        }

        this.$store.commit("order/SET_ITEM", {
          kargoPrice: 0,
        });
        this.setListPrice([]);
        this.loadingCheck = true;
        this.setColly();
        if (
          this.totalWeight != 0 &&
          this.$store.state.order.recipientData.recipientIDCountry != ""
        ) {
          //check service ECO
          // if (this.service == "ECO") {
          //   this.totalWeight = this.totalWeight < 3 ? 3 : this.totalWeight;
          // }
          this.setItemDetails(this.itemDetail);
          let res = await axios({
            method: "post",
            url: `${baseUrl}/CheckPriceInternational`,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: {
              Length: this.$store.state.order.itemData.collies.length,
              Height: this.$store.state.order.itemData.collies.height,
              Width: this.$store.state.order.itemData.collies.width,
              Weight: this.totalWeight,
              IDCityOrigin: this.$store.state.order.shipperData.shipperCityId,
              KodePosDestination:
                this.$store.state.order.recipientData.recipientPostCode.toString(),
              KCX_ViaIDVia: 4,
              KCX_ServiceIDService: 9,
              IDCountry:
                this.$store.state.order.recipientData.recipientIDCountry,
              Service: this.$store.state.order.itemData.service,
              KategoryPaket: this.tab,
              KodeCityOrigin: `${getProfileCookie().IDCity}`,
              CityName: this.cityName,
              PackageCategory: "NON ELEKTRONIK",
            },
          });
          // set list price
          let price = res.data.data.price.filter(
            (el) => el.service == this.service
          );
          this.setListPrice(price);
          this.listPrice.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          this.loadingCheck = false;

          let collies = {
            ...this.$store.state.order.itemData.collies,
            mostWeight: res.data.data.mostWeight,
            // totalBiaya: res.data.data.price,
          };
          this.$store.commit("order/SET_ITEM", {
            collies,
            price3PL: Number(res.data.data.additionalPrice),
            totalWeight: res.data.data.mostWeight,
          });
          this.setInsurance(this.isInsurance);
        } else {
          this.loadingCheck = false;
          this.snackbar = true;
          this.snackbarText = "Pastikan Berat dan Negara Tujuan Telah Terisi";
        }
      } catch (err) {
        this.snackbar = true;
        this.snackbarText = err.response.data.message.error[0];
      } finally {
        this.loadingCheck = false;
      }
    },
    setPrice(data, index) {
      this.choice = null;
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: data.price,
        rate3PL: data.rate,
        prefixOrder: data.category,
        vendor: data.vendor,
        etd: data.etd,
        moda: this.service,
      });
      let collies = {
        ...this.$store.state.order.itemData.collies,
        // mostWeight: data.mostWeight,
        totalBiaya: data.price,
      };
      this.$store.commit("order/SET_ITEM", { collies });
      // this.setInsurance(this.isInsurance);
      this.setChoice(index);
    },
    countDetailItems(e) {
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });

      this.setListPrice([]);
      this.itemDetail = [];
      this.colly.weight = 0;
      for (let index = 0; index < e; index++) {
        this.itemDetail.push({
          description: this.itemDetail.description,
          weight: this.itemDetail.weight,
          price: this.itemDetail.price,
          hscode: this.itemDetail.hscode,
          pieces: this.itemDetail.pieces,
        });
      }
    },
    countDetailItemsStore(e, dataStore) {
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });

      this.setListPrice([]);
      this.itemDetail = [];
      this.colly.weight = 0;
      for (let index = 0; index < e; index++) {
        this.itemDetail.push({
          description: dataStore[index].description,
          weight: dataStore[index].weight,
          price: dataStore[index].price,
          hscode: dataStore[index].hscode,
          pieces: dataStore[index].pieces,
        });
      }
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
    calculatedWeightToPrice(price) {
      const perKiloPrice = price / 10;
      if (this.$store.state.order.itemData.totalWeight > 10) {
        return this.$store.state.order.itemData.totalWeight * perKiloPrice;
      }
      return this.$store.state.order.itemData.totalWeight * perKiloPrice;
    },
    clear() {
      this.$store.commit("order/CLEAR_DATA");
      // this.valid = this.$refs.form.resetValidation();
      this.showDialog = false;
      window.location.reload();
      window.scrollTo({ top: 0 });
      // this.$router.push("/dashboard/order-confirmation");
    },
    convertToRp(bil) {
      return formatToRupiah(bil);
    },
    addAdditionalCost() {
      this.additionalCost.push({
        typeAdditional: "",
        costAdditional: 0,
      });
      this.totalAddCost();
    },
    removeAdditionalCost(index) {
      this.additionalCost.splice(index, 1);
      this.totalAddCost();
    },
    fetchTempOrder() {
      this.loadingListOrder = true;
      this.orderList = [];
      axios({
        url: `${baseUrl}/HubInboundOrders/tmpOrder/${
          getProfileCookie().KCX_HubIDHub
        }/international`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          let data = res.data.data;
          data.map((item) => {
            let dataOrder = {
              ...JSON.parse(item.DataOrder),
              OrderModule: item.OrderModule,
              IDTempOrder: item.IDTempOrder,
              BiayaKirim: item.BiayaKirim,
              TotalBiaya: item.TotalBiaya,
              KomisiHO: item.KomisiHO,
              KomisiHub: item.KomisiHub,
              TotalBiayaHub: item.TotalBiayaHub,
            };
            this.orderList.push(dataOrder);
          });
        })
        .catch((err) => {
          console.log(err.response.data.message.error[0]);
        })
        .finally(() => {
          this.loadingListOrder = false;
        });
    },

    sumBiaya() {
      return this.orderList.reduce(
        (a, b) => a + (b["item"]["TotalBiaya"] || 0),
        0
      );
    },
    sumBiayaKirim() {
      return this.orderList.reduce(
        (a, b) => a + (b["item"]["BiayaKirim"] || 0),
        0
      );
    },
    sumKomisiHub() {
      return this.orderList.reduce((a, b) => a + (b["KomisiHub"] || 0), 0);
    },
    sumKomisiHO() {
      return this.orderList.reduce((a, b) => a + (b["KomisiHO"] || 0), 0);
    },
    sumTotalBiayaHub() {
      return this.orderList.reduce((a, b) => a + (b["TotalBiayaHub"] || 0), 0);
    },
    async deleteOrder(item) {
      this.isLoadingDelete = true;
      axios({
        url: `${baseUrl}/HubInboundOrders/tmpOrder/delete/${item.IDTempOrder}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then(() => {
          this.snackbar = true;
          this.isLoadingDelete = false;
          this.snackbarText = "Order Deleted";
          this.fetchTempOrder();
          if (this.orderList < 1) {
            this.sheet = false;
          }
        })
        .catch((err) => {
          this.isLoadingDelete = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ItemFormComponent {
  padding: 0px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    gap: 12px;
  }
}
</style>
