import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";

export const masterDataModule = {
  namespaced: true,
  state: () => ({
    via: [],
  }),

  mutations: {
    _VIA(state, payload) {
      state.via = payload;
    },
  },

  actions: {
    async getVia({ commit }, TypeVia) {
      try {
        let { data: resp } = await axios({
          method: "get",
          url: `${baseUrl}/masterData/via`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        let via = resp.data.filter((item) => item.TypeVia == TypeVia);

        commit("_VIA", via);
        return via;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },

  getters: {},
};
