import Vue from "vue";
import Vuex from "vuex";

import { orderModule } from "./modules/createOrderModule.js";
import { userModule } from "./modules/userModule";
import { shipperModule } from "./modules/shipperModule";
import { masterDataModule } from "./modules/masterDataModule";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    order: orderModule,
    user: userModule,
    shipper: shipperModule,
    masterData: masterDataModule,
  },
});
