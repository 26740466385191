<template>
  <div class="Home">
    <v-row>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">ORDER</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ orderCount }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Total Order List Hari Ini</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-icon x-large color="orange darken-2">
              mdi-arrow-up-bold-box-outline
            </v-icon>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              block
              small
              router
              to="/dashboard/order-confirmation"
            >
              Lihat Order List
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">MANIFEST</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ manifestCount }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Paket Ready untuk Manifest</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-icon x-large color="blue darken-2"> mdi-email </v-icon>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              block
              small
              router
              to="/dashboard/manage-manifest"
            >
              LIHAT MANIFEST
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">TOPUP</div>
              <v-list-item-title class="text-h5 mb-1">
                {{ topupCount }}
              </v-list-item-title>
              <v-list-item-subtitle
                >Topup Di Proses MasterHub</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-icon x-large color="purple darken-2"> mdi-call-split </v-icon>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              rounded
              text
              block
              small
              router
              to="/dashboard/wallet"
            >
              LIHAT TOPUP
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto text-center" light width="100%">
          <v-card-text>
            <v-sheet color="white">
              <v-sparkline
                :value="values"
                :labels="labels"
                color="blue"
                height="100"
                padding="10"
                stroke-linecap="round"
                :show-labels="true"
                smooth
              >
                <template v-slot:label="item"> {{ item.value }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">Sales Bulan Ini</div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center">
            <v-btn block tex router to="/dashboard/order-confirmation">
              Lihat Transaksi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnacbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { getTokenCookie, getProfileCookie } from "../../utils/cookies";
import { baseUrl } from "../../constant/url";
import timezone from "moment-timezone";

export default {
  name: "Home",
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      colorSnacbar: "green",
      orderMonth: [],
      orderCount: 0,
      manifestCount: 0,
      topupCount: 0,
      idhub: getProfileCookie().KCX_HubIDHub,
    };
  },
  created() {
    this.getSummary();
  },
  computed: {
    values() {
      return this.orderMonth.map((x) => Number(x.orderCount));
    },
    labels() {
      return this.orderMonth.map((x) => timezone(x.date).format("DD"));
    },
  },
  methods: {
    async getSummary() {
      try {
        let summary = await axios({
          method: "GET",
          url: `${baseUrl}/dashboard/summary`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            idhub: this.idhub,
          },
        });
        this.orderMonth = summary.data.data.orderMonth;
        this.orderCount = summary.data.data.orderCount;
        this.manifestCount = summary.data.data.manifestCount;
        this.topupCount = summary.data.data.topupCount;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.PackingList {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;

  &-Option {
    display: flex;
    justify-content: space-between;

    .container {
      #button {
        justify-content: end;
      }
    }

    .search {
      display: flex;
    }
  }
}
</style>
