<template>
  <div class="SideBarComponent">
    <div class="SideBarComponent-Title">
      <!-- <h1>TROLLY</h1> -->
      <!-- <h4>{{ hubName }}</h4> -->
      <!-- <h4>SOLARNTE HUB</h4> -->
      <img :src="trollyTransparentLogo" alt="Trolly" id="TrollyLogo" />
    </div>

    <v-list color="#4ECCD4" dense class="SideBarComponent-Navigation">
      <v-list-item router to="/dashboard/home" color="#236E73">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>HOME</v-list-item-title>
      </v-list-item>
      <v-list-item router to="/dashboard/wallet" color="#236E73">
        <v-list-item-icon>
          <v-icon>mdi-wallet</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Wallet</v-list-item-title>
      </v-list-item>
      <v-list-item router to="/dashboard/order-confirmation" color="#236E73">
        <v-list-item-icon>
          <v-icon>mdi-cart</v-icon>
        </v-list-item-icon>

        <v-list-item-title>ORDER LIST</v-list-item-title>
      </v-list-item>

      <v-list-item router to="/dashboard/financial-report" color="#236E73">
        <v-list-item-icon>
          <v-icon>mdi-cloud-print-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>FINANCIAL REPORT</v-list-item-title>
      </v-list-item>

      <!-- <v-list-item router to="/dashboard/cek-tarif" color="#236E73">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text-search-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>CEK TARIF</v-list-item-title>
      </v-list-item> -->

      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        color="#236E73"
        :disabled="
          checkUser && !(item.title == 'OPERATION' || item.title == 'DELIVERY')
        "
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          router
          :to="child.route.path"
          color="#236E73"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import { dashboardRoute } from "@/constant/routeName.js";
import { userType } from "../../constant/user";
import { getProfileCookie } from "../../utils/cookies";
import trollyTransparentLogo from "../../assets/images/trolly-logo-black.png";

export default {
  name: "SideBarComponent",
  data() {
    return {
      items: [
        {
          action: "mdi-clipboard-text-search-outline",
          items: [
            {
              title: "Domestik",
              route: dashboardRoute.fareCheck,
            },
            {
              title: "International",
              route: dashboardRoute.fareCheckInternational,
            },
          ],
          title: "CHECK TARIF",
        },

        // {
        //   action: "mdi-package-variant-closed",
        //   items: [
        //     // {
        //     //   title: "Order Management",
        //     //   route: dashboard.orderManagement,
        //     // },
        //     {
        //       title: "Job Order",
        //       route: dashboardRoute.jobOrder,
        //     },
        //     {
        //       title: "Manage Pickup",
        //       route: dashboardRoute.managePickup,
        //     },
        //     // {
        //     //   title: "Create Order",
        //     //   route: dashboardRoute.createOrder,
        //     // },
        //     // {
        //     //   title: "Order Confirmation",
        //     //   route: dashboardRoute.orderConfirmation,
        //     // },
        //   ],
        //   title: "INBOUND",
        // },
        {
          action: "mdi-briefcase",
          items: [
            {
              title: "Bagging",
              route: dashboardRoute.bagging,
            },
            {
              title: "Packing List",
              route: dashboardRoute.packingList,
            },
            {
              title: "Manage Manifest",
              route: dashboardRoute.manageManifest,
            },
            {
              title: "Manage Outbound",
              route: dashboardRoute.manageOutbound,
            },
            // {
            //   title: "Incoming",
            //   route: dashboardRoute.incoming,
            // },
            // {
            //   title: "Unbagging",
            //   route: dashboardRoute.unbagging,
            // },
          ],
          title: "OPERATION",
        },
        // {
        //   action: "mdi-truck-delivery",
        //   items: [
        //     {
        //       title: "Irregularity",
        //       route: dashboardRoute.irregularity,
        //     },
        //     {
        //       title: "Delivery Run Sheet",
        //       route: dashboardRoute.deliveryRunSheet,
        //     },
        //     {
        //       title: "Manage Delivery",
        //       route: dashboardRoute.manageDelivery,
        //     },
        //     {
        //       title: "Outgoing",
        //       route: dashboardRoute.outgoing,
        //     },
        //   ],
        //   title: "DELIVERY",
        // },
        {
          action: "mdi-folder",
          items: [
            // {
            //   title: "Shipment Status",
            //   route: dashboardRoute.shipmentStatus,
            // },
            // {
            //   title: "Detail Transaction",
            //   route: dashboardRoute.detailTransaction,
            // },
            {
              title: "Recap Transaction",
              route: dashboardRoute.recapTransaction,
            },
            // {
            //   title: "Wallet",
            //   route: dashboardRoute.wallet,
            // },
          ],
          title: "REPORTS",
        },
        {
          action: "mdi-cog",
          items: [
            // {
            //   title: "Area Management",
            //   route: dashboard.areaManagement,
            // },
            {
              title: "User Management",
              route: dashboardRoute.userManagement,
            },
            // {
            //   title: "Hub Management",
            //   route: dashboard.hubManagement,
            // },
            // {
            //   title: "Vendor Management",
            //   route: dashboardRoute.vendorManagement,
            // },
            {
              title: "Profile",
              route: dashboardRoute.profile,
            },
          ],
          title: "SETTINGS",
        },
      ],
      trollyTransparentLogo,
    };
  },
  computed: {
    checkUser() {
      const type = getProfileCookie().KCX_UserTypeIDUserType;
      return type === userType.OPERATION_HUB;
    },
    hubName() {
      return getProfileCookie().NamaHub;
    },
  },
};
</script>

<style lang="scss" scoped>
.SideBarComponent {
  background-color: #4eccd4;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: scroll;

  &-Title {
    background-color: #4eccd4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//   background: #ff0000;
// }
#TrollyLogo {
  width: 10rem;
}
</style>
