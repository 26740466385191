<template>
  <div class="Wallet">
    <div class="Wallet-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col cols="4">
            <v-card elevation="2" max-width="600" :loading="loadingSaldo">
              <v-card-title
                ><h3>
                  Total Saldo
                  <a @click="view" v-if="showSaldo"
                    ><v-icon> mdi-eye </v-icon></a
                  ><a @click="view" v-else><v-icon> mdi-eye-off </v-icon></a>
                </h3>
              </v-card-title>
              <v-card-text>
                <h1 class="mt-5">
                  <span v-if="showSaldo"
                    >{{ balance }}
                    <v-btn icon color="green" @click="fetchBalance">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn></span
                  ><span v-else>*********</span>
                </h1>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  class="white--text"
                  @click="popupTopup = true"
                  right
                >
                  Topup
                  <v-icon right dark> mdi-plus </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card elevation="2" max-width="600" :loading="loadingSaldo">
              <v-card-title><h4>No. Rekening Master Hub</h4> </v-card-title>
              <v-card-text>
                <v-btn-toggle
                  tile
                  color="blue accent-3"
                  group
                  class="d-flex flex-wrap"
                >
                  <v-btn
                    class="pa-4"
                    small
                    v-for="norek in norekHO"
                    :key="norek.IDNorek"
                  >
                    {{ norek.NomorRekening }}
                    <v-chip small color="orange">{{
                      norek.NamaBank
                    }}</v-chip></v-btn
                  >
                </v-btn-toggle>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-row>
      <!-- <v-col cols="4">
        <v-card class="pa-3">
          <v-card-title class="pa-5">
            <v-row>
              <v-col>
                <h4>Riwayat TOPUP</h4>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headersTopup"
              :items="items"
              :loading="isLoading"
              item-key="packingId"
              v-model="selected"
              :show-select="isShowCheckbox"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <v-card class="pa-3">
          <v-card-title class="pa-5">
            <v-row>
              <v-col cols="5">
                <v-btn
                  depressed
                  :color="dataRiwayat == 'transaction' ? 'primary' : ''"
                  @click="loadData('transaction')"
                >
                  Riwayat Transaksi
                </v-btn>
                &nbsp;

                <v-btn
                  depressed
                  :color="dataRiwayat == 'topup' ? 'primary' : ''"
                  @click="loadData('topup')"
                >
                  Riwayat TopUp
                </v-btn>
              </v-col>
              <!-- <v-col cols="3">
                <v-text-field
                  label="Cari Transaksi"
                  prepend-icon="mdi-cached"
                ></v-text-field>
              </v-col> -->
              <v-col cols="7">
                <div class="d-flex flex-row align-start">
                  <v-menu
                    ref="menu"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                      @input="menuDate = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menu"
                    v-model="menuDateend"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      @input="menuDateend = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-btn
                    depressed
                    color="cyan"
                    class="mt-4 ml-3 white--text"
                    @click="submit"
                    small
                  >
                    Search
                  </v-btn>
                  <v-btn
                    depressed
                    color="red"
                    class="mt-4 ml-3 white--text"
                    @click="reset"
                    small
                  >
                    Reset
                  </v-btn>
                  <v-btn
                    depressed
                    color="green"
                    class="mt-4 ml-3 white--text"
                    @click="exportToExcel"
                    small
                  >
                    Export
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text v-if="dataRiwayat == 'topup'">
            <v-data-table
              :headers="headersTopup"
              :items="itemsTopup"
              :sort-by="sortByTopup"
              :sort-desc="[true]"
              :loading="isLoading"
              ><template v-slot:[`item.AmountFmt`]="{ item }">
                {{ formatToRupiah(item.Amount + item.NoBuffer) }}
              </template>
              <template v-slot:[`item.createdAtFmt`]="{ item }">
                {{ formatToDate(item.createdAt) }}
              </template>
              <template v-slot:[`item.uploadTF`]="{ item }">
                <div v-if="item.BuktiTF">
                  <a :href="item.BuktiTF" target="_blank">Lihat Bukti TF</a>
                </div>
                <dialog-upload-TF-component
                  v-else
                  :loadRiwayatTopup="getRiwayatTopup"
                  :loadBalance="fetchBalance"
                  :itemData="item"
                  :startDate="startDate"
                  :endDate="endDate"
                />
              </template>
              <template v-slot:[`item.valid`]="{ item }">
                <span v-if="item.BuktiTF">
                  <span v-if="item.IsValid == true">
                    <v-chip class="ma-2 white--text" color="green" small>
                      Success</v-chip
                    ></span
                  >
                  <span v-else-if="item.IsValid == false">
                    <v-chip class="ma-2 white--text" color="red" small>
                      Reject</v-chip
                    ></span
                  >
                  <span v-else>
                    <v-chip class="ma-2 white--text" color="info" small>
                      Review</v-chip
                    >
                  </span>
                </span>
                <span v-else>
                  <v-chip class="ma-2 white--text" color="secondary" small>
                    belum upload
                  </v-chip>
                </span>
                <button></button>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="dataRiwayat == 'transaction'">
            <v-data-table
              :headers="headers"
              :items="transaction"
              disable-sort
              :search="search"
            >
              <template v-slot:top>
                <dialog-order-transaction-component ref="detailOrderDialog" />
              </template>
              <template v-slot:item.OrderID="{ item }">
                <div class="pa-1"></div>

                <span v-if="item.topuphub">
                  <div class="pa-1">
                    Norek : <strong>{{ item.topuphub.Norek }}</strong>
                  </div>
                </span>
                <span v-else>
                  <div
                    class="pa-1"
                    v-for="(order, index) in item.order"
                    :key="index"
                  >
                    waybill :<a @click="showDetail(item.OrderID)"
                      ><strong> {{ order.InboundOrder.WBNumber }}</strong></a
                    >
                    <br />
                    berat : {{ order.InboundOrder.TotalBerat }}
                    <br />
                    biaya : {{ order.InboundOrder.TotalBiaya }}
                  </div>
                </span>
              </template>
              <template v-slot:item.TransactionType="{ item }">
                <v-chip
                  v-if="item.TransactionType == 'ORDER'"
                  color="blue"
                  text-color="white"
                  x-small
                  >{{ item.TransactionType }}</v-chip
                >
                <v-chip
                  v-if="item.TransactionType == 'TOPUP'"
                  color="green"
                  text-color="white"
                  x-small
                  >{{ item.TransactionType }}</v-chip
                >
                <v-chip
                  v-if="item.TransactionType == 'REFUND'"
                  color="orange"
                  text-color="white"
                  x-small
                  >{{ item.TransactionType }}</v-chip
                >
              </template>
              <template v-slot:item.Date="{ item }">
                <span>{{ formatDate(item.Date) }}</span>
              </template>
              <template v-slot:item.HubName="{ item }">
                <span v-if="item.TransactionType != 'TOPUP'">
                  <br />
                  <span v-if="item.order[0]">{{
                    item.order[0].OrderType
                  }}</span>
                  <span v-if="item.topuphub">{{
                    item.topuphub.NamaBank
                  }}</span></span
                ><span v-else>Topup Saldo</span>
              </template>
              <template v-slot:item.Amount="{ item }">
                <span v-if="item.IsDebit" class="green--text"
                  ><strong>{{ formatToRupiah(item.Amount) }}</strong></span
                >
                <span v-else class="red--text"
                  ><strong>{{ formatToRupiah(item.Amount) }}</strong></span
                >
              </template>
              <template v-slot:item.AmountDebit="{ item }">
                <span v-if="!item.IsDebit">{{
                  formatToRupiah(item.Amount)
                }}</span>
              </template>
              <template v-slot:item.AmountCredit="{ item }">
                <span v-if="item.IsDebit">{{
                  formatToRupiah(item.Amount)
                }}</span>
              </template>
              <template v-slot:item.Balance="{ item }">
                <span>{{ formatToRupiah(item.NewBalance) }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="popupTopup"
      persistent
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>TOP UP</v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div>Jumlah Top Up</div>
                <div>
                  <small>Masukkan nominal yang anda inginkan</small>
                </div>
                <!-- <div class="mt-3 mb-3">
                  <v-btn-toggle
                    v-model="amount"
                    tile
                    color="deep-orange accent-3"
                    group
                  >
                    <v-btn small value="100000"> Rp. 100.000 </v-btn>
                    <v-btn small value="300000"> Rp. 300.000 </v-btn>
                    <v-btn small value="500000"> Rp. 500.000 </v-btn>
                    <v-btn small value="1000000"> Rp. 1.000.000 </v-btn>
                  </v-btn-toggle>
                </div> -->
                <div>
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    hide-spin-buttons
                    min="10000"
                    v-money="money"
                  ></v-text-field>
                </div>

                <div>Nomor Rekening</div>

                <div class="mt-3 mb-3">
                  <v-btn-toggle
                    tile
                    color="blue accent-3"
                    group
                    v-model="dataNorek"
                    class="d-flex flex-wrap"
                  >
                    <v-btn
                      class="pa-4"
                      small
                      v-for="norek in norekHO"
                      :key="norek.IDNorek"
                      :value="norek"
                    >
                      {{ norek.NomorRekening }}
                      <v-chip small color="orange">{{
                        norek.NamaBank
                      }}</v-chip></v-btn
                    >
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="popupTopup = false">Batal</v-btn>
            <v-btn @click="topupSaldo" class="mb-2" color="red" dark
              >Isi Saldo
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnacbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import timezone from "moment-timezone";
import { formatToRupiah } from "../../../utils/formatter";
import DialogUploadTFComponent from "./../../../components/dashboard/inbound/DialogUploadTFComponent.vue";
import DialogOrderTransactionComponent from "../../../components/dashboard/inbound/DialogOrderTransactionComponent.vue";
import exportFromJSON from "export-from-json";

export default {
  name: "Wallet",
  components: { DialogUploadTFComponent, DialogOrderTransactionComponent },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      colorSnacbar: "green",
      loadingSaldo: false,
      showSaldo: false,
      menuDate: false,
      menuDateend: false,
      valid: false,
      dataNorek: "",
      popupTopup: false,
      amount: 100000,
      money: {
        decimal: ",",
        thousands: ".",
        precision: 0,
        masked: false,
      },
      startDate: timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: timezone()
        .tz("Asia/Jakarta")
        .endOf("month")
        .format("YYYY-MM-DD"),
      message: "",
      sortBy: [
        // "packingId",
        "date",
        "destination",
        "totalColly",
        "totalWeight",
        // "status",
      ],
      sortByTopup: [
        // "packingId",
        "date",
        "destination",
        "totalColly",
        "totalWeight",
        // "status",
      ],
      isLoading: false,
      city: "",
      toDate: "",
      fromDate: "",
      headers: [
        { text: "Trx. ID", value: "TransactionID" },
        {
          text: "Date",
          align: "start",
          filterable: false,
          value: "Date",
        },
        { text: "Service", value: "HubName" },
        // { text: "Service", value: "Service" },
        { text: "Trx. Type", value: "TransactionType" },
        { text: "Detail Order", value: "OrderID" },
        { text: "Debit", value: "AmountDebit" },
        { text: "Credit", value: "AmountCredit" },
        { text: "Balance", value: "Balance" },
      ],
      headersTopup: [
        {
          text: "Tanggal",
          value: "createdAtFmt",
          filterable: false,
        },
        { text: "Bank", value: "NamaBank", filterable: false },
        { text: "Norek", value: "Norek", filterable: false },
        {
          text: "Nominal",
          value: "AmountFmt",
          filterable: false,
        },
        { text: "Bukti TF", value: "uploadTF", filterable: false },

        { text: "Status", value: "valid", filterable: false },
      ],
      items: [],
      itemsTopup: [],
      selected: [],
      isShowCheckbox: false,
      norekHO: [],
      IDHO: getProfileCookie().KCX_HOIDHO,
      HubID: getProfileCookie().KCX_HubIDHub,
      balance: 0,
      dataRiwayat: "transaction",
      transaction: [],
      search: "",
      idTrx: "",
    };
  },
  methods: {
    loadData(mode) {
      this.dataRiwayat = mode;
    },
    async fetchBalance() {
      this.isLoading = true;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/BalanceHub/${this.HubID}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.balance = formatToRupiah(res.data.data.balance ?? 0);
      this.isLoading = false;
    },
    formatToRupiah(str) {
      return formatToRupiah(str);
    },
    formatToDate(date) {
      // return date;
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm");
    },
    view() {
      this.showSaldo = !this.showSaldo;
    },
    submit() {
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }
      this.getRiwayatTopup(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
      this.fetchTransaction(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    refresh() {
      this.getRiwayatTopup(this.startDate, this.endDate);
    },
    async getNorek() {
      let norek = await axios({
        method: "get",
        url: `${baseUrl}/Norek/${this.IDHO}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.norekHO = norek.data.data;
    },
    async getRiwayatTopup(startDate, endDate) {
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      let riwayatTopup = await axios({
        method: "get",
        url: `${baseUrl}/Balance/TopupHub/${this.HubID}${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.itemsTopup = riwayatTopup.data.data;
    },
    async topupSaldo() {
      try {
        if (this.dataNorek.length == 0) {
          this.snackbar = true;
          this.colorSnacbar = "red";
          this.snackbarText = "Silahkan Pilih Bank Terlebih Dahulu";
          return;
        }
        // console.log(this.amount.replaceAll(".", ""));
        if (this.amount.replaceAll(".", "") < 10000) {
          this.snackbar = true;
          this.colorSnacbar = "red";
          this.snackbarText = "Minimal Nominal Adalah Rp. 10.000";
          return;
        }

        this.popupTopup = false;
        this.isLoading = true;
        let topup = await axios({
          method: "post",
          url: `${baseUrl}/Balance/TopupHub/${this.HubID}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            IDHub: this.HubID,
            IDHO: this.IDHO,
            Amount: this.amount.replaceAll(".", ""),
            Norek: this.dataNorek.NomorRekening,
            NamaBank: this.dataNorek.NamaBank,
          },
        });
        this.dataNorek = {};
        this.amount = 0;
        this.isLoading = false;
        this.snackbar = true;
        this.colorSnacbar = "green";
        this.snackbarText = topup.data.message.info[0];
        this.getRiwayatTopup(this.startDate, this.endDate);
        this.dataRiwayat = "topup";
      } catch (error) {
        this.isLoading = false;
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = error.message;
      }
    },
    async fetchTransaction(startDate, endDate) {
      this.isLoading = true;
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/TransactionHub/${this.HubID}${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.transaction = res.data.data;

      this.isLoading = false;
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY");
    },
    showDetail(idTrx) {
      // console.log(idTrx);
      // this.idTrx = idTrx;
      this.$refs.detailOrderDialog.toggleDialog();
      this.$refs.detailOrderDialog.fetchOrder(idTrx);
    },
    exportToExcel() {
      const currentData = this.transaction;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          Date: timezone(el.Date)
            .tz("Asia/Jakarta")
            .format("YYYY-MM-DD HH:mm:ss"),
          "Trx. ID": el.TransactionID,
          "Trx. Type": el.TransactionType,
          "Order ID": el.OrderID,
          Debit: el.IsDebit ? "" : el.Amount,
          Credit: el.IsDebit ? el.Amount : "",
          Balance: el.NewBalance,
        });

        number++;
      });

      const fileName = `History Topup - Transaction HUB ${this.startDate} ~ ${this.endDate}`;
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.fetchTransaction(this.startDate, this.endDate);
    },
  },
  created() {
    this.getRiwayatTopup(this.startDate, this.endDate);
    this.getNorek();
    this.fetchBalance();
    this.fetchTransaction(this.startDate, this.endDate);
  },
};
</script>

<style lang="scss" scoped>
.PackingList {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;

  &-Option {
    display: flex;
    justify-content: space-between;

    .container {
      #button {
        justify-content: end;
      }
    }

    .search {
      display: flex;
    }
  }
}
</style>
