<template>
  <div>
    <v-dialog v-model="dialogResetPassword" persistent width="600px">
      <v-card>
        <v-card-title> Reset Password </v-card-title>

        <v-card-text>
          <h3>{{ user.name }}</h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="mt-4">
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    outlined
                    dense
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="confirmPassword"
                    :rules="confirmPasswordRules"
                    outlined
                    dense
                    label="Confirm Password"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            color="warning"
            depressed
            @click="submit"
            :loading="loadingSubmit"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogResetPasswordComponent",
  props: ["user"],
  data() {
    return {
      dialogResetPassword: false,
      snackbar: false,
      snackbarText: "",
      loadingSubmit: false,
      password: "",
      confirmPassword: "",
      valid: false,
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => (value && value.length >= 3) || "minimum 3 characters",
      ],
      confirmPasswordRules: [
        (value) => !!value || "type confirm password",
        (value) =>
          value === this.password ||
          "The password confirmation does not match.",
      ],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogResetPassword = !this.dialogResetPassword;
    },
    submit() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/User/${this.user.idUser}/Password`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            password: this.password,
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
};
</script>
