<template>
  <div class="DialogDetailOrderComponent">
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda yakin cancel order ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="doCancelOrder"
            :loading="isLoadingCancel"
            >Ya</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailOrderDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="toggleDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detail Order</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="mt-5"> </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <div v-if="order.orderType == 'domestic'">
            <h3 class="mt-3">Shipper Information</h3>
            <hr />
            <br />
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-0">
                      <h4>Nama Shipper</h4>
                    </v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.ShipperLoc.Nama"
                        outlined
                        dense
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>No. Handphone</h4></v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.ShipperLoc.Telp"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Alamat</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-textarea
                        :value="order.detail.Order.ShipperLoc.Alamat"
                        outlined
                        rows="3"
                        no-resize
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <h3 class="mt-3">Recipient Information</h3>
            <hr />
            <br />
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-0">
                      <h4>Nama Penerima</h4>
                    </v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.Recipient.Nama"
                        outlined
                        dense
                        readonly
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>No. Handphone</h4></v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.Recipient.Telp"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Email</h4></v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.Recipient.Alamat"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Kota</h4></v-col>

                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.Recipient.Kota"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <!-- <v-row>
                  <v-col cols="4" class="pa-0"> <h4>Kelurahan</h4></v-col>

                  <v-col cols="7" class="pa-0">
                    <v-text-field
                      :value="order.detail.Order.Recipient.Alamat"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row> -->

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Kode Pos</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-text-field
                        :value="order.detail.Order.Recipient.KelurahanKodePos"
                        outlined
                        dense
                        disabled
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Alamat</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-textarea
                        :value="order.detail.Order.Recipient.Alamat"
                        outlined
                        rows="3"
                        no-resize
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <h3>Package Information</h3>
            <hr />
            <br />
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-container>
                    <v-row>
                      <v-col cols="4" class="pa-0">
                        <h4>Via Moda</h4>
                      </v-col>

                      <v-col cols="7" class="pa-0">
                        <v-text-field
                          :value="order.detail.Order.Moda"
                          outlined
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="pa-0">
                        <h4>Tipe Paket</h4>
                      </v-col>

                      <v-col cols="7" class="pa-0">
                        <v-text-field
                          :value="order.detail.Order.Kategory"
                          outlined
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="pa-0"> <h4>Total Koli</h4></v-col>

                      <v-col cols="3" class="pa-0">
                        <v-text-field
                          :value="order.detail.TotalKoli"
                          outlined
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="pa-0"> <h4>Keterangan</h4></v-col>

                      <v-col cols="7" class="pa-0">
                        <v-textarea
                          outlined
                          rows="2"
                          no-resize
                          :value="order.detail.Keterangan"
                          readonly
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-0">
                      <h4>Instruksi Khusus</h4></v-col
                    >

                    <v-col cols="8" class="pa-0">
                      <v-textarea
                        readonly
                        outlined
                        rows="2"
                        no-resize
                        :value="order.detail.Instruction"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Diskon</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-text-field
                        type="number"
                        outlined
                        dense
                        disabled
                        :value="order.detail.Diskon"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Nilai Barang</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-text-field
                        type="number"
                        outlined
                        dense
                        :value="order.detail.EstimasiNilai"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-0"> <h4>Asuransi</h4></v-col>

                    <v-col cols="8" class="pa-0">
                      <v-text-field
                        outlined
                        dense
                        :value="order.detail.Order.IsInsurance ? 'Ya' : 'Tidak'"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row>
                    <v-col cols="12">
                      <div
                        class="ItemFormComponent-Result"
                        v-if="order.detail.Order.Collies.length > 0"
                      >
                        <v-data-table
                          :headers="headers"
                          :items="order.detail.Order.Collies"
                        >
                          <template v-slot:[`item.total`]="{ item }">
                            {{ item.Terberat * item.JumlahKoli }}
                          </template>
                          <template v-slot:[`item.IsPacking`]="{ item }">
                            {{ item.IsPacking ? "Ya" : "Tidak" }}
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <hr />
              <v-row>
                <v-col>
                  <v-container>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Koli</th>
                          <th>Total Berat</th>
                          <th>Total Biaya Packing</th>
                          <th>Biaya Kirim</th>
                          <th>Diskon</th>
                          <th>PPN</th>
                          <th>Asuransi</th>
                          <th>Total Biaya</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ order.detail.TotalKoli }}</td>
                          <td>{{ order.detail.TotalBerat }} Kg</td>
                          <td>
                            {{ toRupiah(order.detail.Order.TotalBiayaPacking) }}
                          </td>
                          <td>
                            {{
                              toRupiah(
                                order.detail.TotalBiaya -
                                  (order.detail.Diskon +
                                    order.detail.PPN +
                                    order.detail.Order.TotalBiayaPacking +
                                    order.detail.Order.Insurance)
                              )
                            }}
                          </td>
                          <td>{{ toRupiah(order.detail.Diskon) }}</td>
                          <td>{{ toRupiah(order.detail.PPN) }}</td>
                          <td>{{ toRupiah(order.detail.Order.Insurance) }}</td>
                          <td>{{ toRupiah(order.detail.TotalBiaya) }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-else>
            <v-row class="mt-5">
              <v-col cols="4">
                <h3>Shipper Information</h3>
                <hr />
                <v-container>
                  <v-row>
                    <v-text-field
                      :value="order.detail.Order.ShipperLoc.Nama"
                      label="Nama Shipper"
                      readonly
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      :value="order.detail.Order.ShipperLoc.Telp"
                      label="No. HP"
                      readonly
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      :value="order.detail.Order.ShipperLoc.Alamat"
                      rows="3"
                      no-resize
                      label="Alamat"
                      readonly
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="8">
                <h3>Recipient Information</h3>
                <hr />
                <v-row>
                  <v-col cols="6">
                    <v-container>
                      <v-row>
                        <v-text-field
                          label="Service"
                          :value="order.detail.Order.Moda"
                          readonly
                        ></v-text-field>
                        <v-text-field
                          :value="
                            order.detail.Order.RecipientInter.Country
                              .CountryName
                          "
                          label="Receiver Country"
                          readonly
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.Nama"
                          readonly
                          label="Nama Penerima"
                        >
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.Email"
                          readonly
                          label="Email"
                        ></v-text-field>
                      </v-row>

                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.IDType"
                          readonly
                          label="ID. Type"
                        ></v-text-field>
                      </v-row>

                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.IDNo"
                          readonly
                          label="ID. No"
                        ></v-text-field>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <v-container>
                      <v-row>
                        <v-text-field
                          :value="
                            order.detail.Order.RecipientInter.ContactPerson
                          "
                          readonly
                          label="PIC"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.Telp"
                          readonly
                          label="Receiver Telephone"
                        ></v-text-field>
                      </v-row>

                      <v-row>
                        <v-text-field
                          :value="order.detail.Order.RecipientInter.KodePos"
                          readonly
                          label="Kode Pos"
                        ></v-text-field>
                      </v-row>

                      <v-row>
                        <v-textarea
                          :value="order.detail.Order.RecipientInter.Alamat"
                          readonly
                          label="Alamat"
                          rows="3"
                          no-resize
                        ></v-textarea>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <h3>Package Information</h3>
              </v-col>
              <v-row>
                <v-col cols="7">
                  <label> Package Type</label>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :value="order.detail.Order.Kategory"
                        readonly
                        label="Kategori"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Length (cm)"
                        :value="order.detail.Order.Collies[0].Panjang"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Width (cm)"
                        :value="order.detail.Order.Collies[0].Lebar"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        type="number"
                        label="Height (cm)"
                        :value="order.detail.Order.Collies[0].Tinggi"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field
                    type="number"
                    label="Total Item"
                    :value="order.detail.Order.ItemDetails.length"
                    readonly
                  ></v-text-field>

                  <v-textarea
                    label="keterangan"
                    rows="2"
                    no-resize
                    :value="order.detail.Keterangan"
                    readonly
                  ></v-textarea>

                  <v-textarea
                    label="Instruksi Khusus"
                    rows="2"
                    no-resize
                    :value="order.detail.Instruction"
                    readonly
                  ></v-textarea>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Item Value"
                        value="IDR"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      md="6"
                      sm="12"
                      v-if="order.detail.Order.AddCosts.length > 0"
                    >
                      <v-card outlined class="pa-3">
                        <h4>Additional Price</h4>
                        <hr />
                        <v-simple-table>
                          <thead>
                            <tr>
                              <td>Name Cost</td>
                              <td>Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in order.detail.Order.AddCosts"
                              :key="item.IDAddCost"
                            >
                              <template v-if="item.Price > 0">
                                <td>{{ item.NameCost }}</td>
                                <td>{{ item.Price }}</td>
                              </template>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                    <v-col
                      md="6"
                      sm="12"
                      v-if="order.detail.Order.Surcharges.length > 0"
                    >
                      <v-card outlined class="pa-3">
                        <h4>Surcharge</h4>
                        <hr />
                        <v-simple-table>
                          <thead>
                            <tr>
                              <td>Name Cost</td>
                              <td>Price</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in order.detail.Order.Surcharges"
                              :key="item.IDSurcharge"
                            >
                              <template v-if="item.Price > 0">
                                <td>{{ item.NameSurcharge }}</td>
                                <td>{{ item.Price }}</td>
                              </template>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                  <v-card
                    outlined
                    shaped
                    class="pa-5 mt-2"
                    v-if="order.detail.Order.ItemDetails.length > 0"
                  >
                    <v-col>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>Item Description</th>
                            <th>Item Weight</th>
                            <th>Item Price</th>
                            <th>Item HSCode</th>
                            <th>Item Pieces</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in order.detail.Order.ItemDetails"
                            :key="item.IDItem"
                          >
                            <td>{{ item.ItemDescription }}</td>
                            <td>{{ item.ItemWeight }}</td>
                            <td>{{ item.ItemPrice }}</td>
                            <td>{{ item.ItemHSCode }}</td>
                            <td>{{ item.ItemPieces }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-card>
                  <v-col>
                    <v-text-field
                      label="Total Berat (Kg) "
                      :value="order.detail.TotalBerat"
                      type="number"
                      class="mt-5"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      type="number"
                      label="Nilai Barang"
                      class="mt-4"
                      :value="order.detail.EstimasiNilai"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <hr />
                  <v-col>
                    <v-text-field
                      type="number"
                      label="Ongkir 3PL"
                      class="mt-4"
                      :value="order.detail.Price3PL"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-row>
                    <v-col class="pa-5 ma-0">
                      <v-text-field
                        type="number"
                        label="Asuransi"
                        class="mt-4"
                        :value="order.detail.Order.Insurance"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <hr />
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-container>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>Koli</th>
                            <th>Total Berat</th>
                            <th>Total Biaya Packing</th>
                            <th>Biaya Kirim</th>
                            <th>Diskon</th>
                            <th>PPN</th>
                            <th>Asuransi</th>
                            <th>Total Biaya</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ order.detail.TotalKoli }}</td>
                            <td>{{ order.detail.TotalBerat }} Kg</td>
                            <td>
                              {{
                                toRupiah(order.detail.Order.TotalBiayaPacking)
                              }}
                            </td>
                            <td>
                              {{
                                toRupiah(
                                  order.detail.TotalBiaya -
                                    (order.detail.Diskon +
                                      order.detail.PPN +
                                      order.detail.Order.TotalBiayaPacking +
                                      order.detail.Order.Insurance)
                                )
                              }}
                            </td>
                            <td>{{ toRupiah(order.detail.Diskon) }}</td>
                            <td>{{ toRupiah(order.detail.PPN) }}</td>
                            <td>
                              {{ toRupiah(order.detail.Order.Insurance) }}
                            </td>
                            <td>{{ toRupiah(order.detail.TotalBiaya) }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <v-container class="mt-5">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text mx-2"
                color="info"
                depressed
                @click="track(order)"
                v-if="
                  order.status != 'ORDER_CANCELLED' &&
                  order.status != 'ORDER_BOOKED'
                "
              >
                Track
              </v-btn>
              <div
                v-if="
                  (order.vendor == 'JNE' || order.vendor == 'SENTRAL') &&
                  order.orderType == 'domestic'
                "
              >
                <v-btn
                  v-if="
                    order.status != 'ORDER_CANCELLED' &&
                    order.status != 'ORDER_BOOKED'
                  "
                  class="white--text mx-2"
                  color="orange"
                  depressed
                  @click="showPOD(order)"
                >
                  POD
                </v-btn>
              </div>
              <div v-if="order.orderType == 'international'">
                <v-btn
                  v-if="
                    order.status != 'ORDER_CANCELLED' &&
                    order.status != 'ORDER_BOOKED'
                  "
                  class="white--text mx-2"
                  color="orange"
                  depressed
                  @click="showPODInternational(order)"
                >
                  POD
                </v-btn>
              </div>
              <div v-if="order.idStatus === 9">
                <v-btn color="error" @click="showDialog = true"
                  >cancel order</v-btn
                >

                <!-- <v-btn
                  :loading="isLoading"
                  class="white--text mx-2"
                  color="cyan"
                  depressed
                  @click="confirmPayment"
                >
                  confirm payment
                </v-btn> -->
              </div>

              <div v-if="order.idStatus != 8">
                <span v-if="order.orderType == 'international'">
                  <v-btn
                    v-if="order.idStatus !== 23"
                    class="ml-2"
                    color="cyan"
                    @click="printLabelInter"
                  >
                    print Label
                  </v-btn>
                  <v-btn
                    v-if="order.idStatus !== 23"
                    class="ml-2"
                    color="cyan"
                    @click="printSttInter"
                  >
                    print STT
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn
                    v-if="order.idStatus !== 23"
                    class="ml-2"
                    color="cyan"
                    @click="printLabel"
                  >
                    print Label
                  </v-btn>
                  <v-btn
                    v-if="order.idStatus !== 23"
                    class="ml-2"
                    color="cyan"
                    @click="printStt"
                  >
                    print STT
                  </v-btn>
                </span>
              </div>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>POD WayBill</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider></v-divider>

        <v-row>
          <v-col v-if="this.photo" class="col-6">
            <v-card>
              <v-img
                :src="this.photo"
                :lazy-src="this.photo"
                aspect-ratio="1"
                class="grey lighten-2 m-5"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title class="text-h6"> Photo </v-card-title>
            </v-card>
          </v-col>
          <v-col v-else class="d-flex child-flex">
            <div class="text-center mt-5">Tidak Ada Data</div></v-col
          >

          <v-col v-if="this.signature" class="col-6">
            <v-card>
              <v-img
                :src="this.signature"
                :lazy-src="this.signature"
                aspect-ratio="1"
                class="grey lighten-2 m-5"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title class="text-h6"> Signature </v-card-title>
            </v-card>
          </v-col>
          <v-col v-else class="d-flex child-flex">
            <div class="text-center mt-5">Tidak Ada Data</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTracking" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Tracking AWB {{ awbTrack }}</span>
        </v-card-title>
        <v-card-text>
          <div v-if="loadingTrack" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-timeline dense clipped v-if="orderType == 'domestic'">
              <v-timeline-item
                v-for="(t, i) in trackingDetail.slice().reverse()"
                :key="i"
                ><strong>{{ t.code }}</strong> <br />
                <small>{{ t.date }}</small> <br />
                <small>{{ t.desc }}</small> <br />
              </v-timeline-item>
            </v-timeline>
            <v-timeline dense clipped v-else>
              <v-timeline-item
                v-for="(t, i) in trackingDetail.slice().reverse()"
                :key="i"
                ><strong>{{ t.code }}</strong> <br />
                <small>{{ t.date }}</small> <br />
                <small>{{ t.desc }}</small> <br />
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogTracking = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPOD" fullscreen>
      <v-card width="100%" height="100%">
        <v-toolbar dark color="orange">
          <v-btn icon dark @click="dialogPOD = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>POD WayBill International</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <iframe id="ifrm" :src="linkPOD" width="100%" height="100%"></iframe>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  baseUrl,
  // trackingJNE,
  // trackingInternational,
  urlTracking,
} from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { cancelOrder } from "../../../services/order";
import { ORDER_CANCELLED } from "../../../constant/status";
import timezone from "moment-timezone";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  name: "DialogDetailOrderComponent",
  props: ["order"],
  data() {
    return {
      detailOrderDialog: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      isLoadingCancel: false,
      dialog: false,
      photo: null,
      signature: null,
      dialogTracking: false,
      trackingDetail: [],
      loadingTrack: false,
      orderType: "",
      awbTrack: "",
      dialogPOD: false,
      linkPOD: "",
      showDialog: false,
      colorSnackbar: "cyan",
      headers: [
        {
          text: "Desc. Item",
          value: "DescriptionItem",
          sortable: false,
          filterable: false,
        },
        { text: "Koli", value: "JumlahKoli" },
        { text: "Panjang", value: "Panjang" },
        { text: "Lebar", value: "Lebar", filterable: false },
        { text: "Tinggi", value: "Tinggi", filterable: false },
        { text: "Berat Item (kg)", value: "Berat", filterable: false },
        { text: "Berat Volume (kg)", value: "BeratVolume", filterable: false },
        { text: "Terberat (kg)", value: "Terberat", filterable: false },
        { text: "Total Berat (kg)", value: "total", filterable: false },
        { text: "Packing Kayu", value: "IsPacking", filterable: false },
      ],
    };
  },

  methods: {
    toRupiah(value) {
      return formatToRupiah(value);
    },
    toggleDialog() {
      this.detailOrderDialog = !this.detailOrderDialog;
    },
    printLabel() {
      // this.$router.push(`/document/label?wb=${this.order.wbNumber}`);
      let routeData = this.$router.resolve(
        `/document/label?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printStt() {
      let routeData = this.$router.resolve(
        `/document/receipt?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printLabelInter() {
      // this.$router.push(`/document/label?wb=${this.order.wbNumber}`);
      let routeData = this.$router.resolve(
        `/document/label-inter?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printSttInter() {
      let routeData = this.$router.resolve(
        `/document/receipt-inter?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    confirmPayment() {
      // console.log(this.order);
      try {
        this.isLoading = true;
        if (this.order.orderType == "domestic") {
          axios({
            method: "put",
            url: `${baseUrl}/HubInboundOrders/${this.order.wbNumber}`,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
              status: 9,
              ordertype: this.order.orderType,
            },
          })
            .then((res) => {
              this.isLoading = false;
              this.snackbar = true;
              this.snackbarText = res.data.message.info[0];
              this.toggleDialog();
              this.$emit("refresh");
            })
            .catch((err) => {
              this.isLoading = false;
              this.snackbar = true;
              this.snackbarText = err.response.data.message.error[0];
            });
        } else {
          // console.log(this.order);
          axios({
            method: "put",
            url: `${baseUrl}/HubInboundOrdersInter/${this.order.wbNumber}`,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
              status: 9,
              ordertype: this.order.orderType,
            },
          })
            .then((res) => {
              this.isLoading = false;
              this.snackbar = true;
              this.snackbarText = res.data.message.info[0];
              this.toggleDialog();
              this.$emit("refresh");
            })
            .catch((err) => {
              this.isLoading = false;
              this.snackbar = true;
              this.snackbarText = err.response.data.message.error[0];
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async doCancelOrder() {
      // console.log(this.order);
      this.isLoadingCancel = true;
      await cancelOrder(this.order.idTransaction, ORDER_CANCELLED);
      // await cancelOrder(this.order.wbNumber, ORDER_CANCELLED);
      this.toggleDialog();
      this.$emit("refresh");
      this.showDialog = false;
      this.snackbar = true;
      this.snackbarText = "Berhasil Cancel Order";
      this.isLoadingCancel = false;
    },
    showPOD(awb) {
      this.photo = "Loading";
      this.signature = "Loading";
      this.dialog = true;
      // let vendor = awb.vendor == "JNE" ? "JNE" : "SC";
      axios({
        method: "GET",
        // url: `${trackingJNE}?carrier=${vendor}&waybill=${awb.wbNumber}`,
        url: `${urlTracking}${awb.wbNumber}`,
      })
        .then((res) => {
          this.photo = res.data.result.data.pod;
          this.signature = null;
          // if (awb.vendor == "JNE") {
          //   if (res.data.data.cnote.photo) {
          //     this.photo = res.data.data.cnote.photo;
          //     this.signature = res.data.data.cnote.signature;
          //   } else {
          //     this.photo = null;
          //     this.signature = null;
          //   }
          // } else {
          //   let response = res.data.data.history.find(
          //     (i) => i.code == "Delivered"
          //   );
          //   if (response) {
          //     this.photo = response.imgPOD;
          //     this.signature = null;
          //   } else {
          //     this.photo = null;
          //     this.signature = null;
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.snackbar = true;
          this.dialog = false;
          this.snackbarText = "POD Tidak Di Temukan";
        });
    },
    showPODInternational(data) {
      if (data.linkPOD) {
        // this.dialogPOD = true;
        // this.linkPOD = data.linkPOD;
        window.open(data.linkPOD, "_blank", "");
      } else {
        this.snackbar = true;
        this.snackbarText = "POD Tidak Di Temukan";
      }
    },
    track(data) {
      // console.log(data.wbNumber);

      this.awbTrack = data.wbNumber;
      this.dialogTracking = true;
      this.loadingTrack = true;
      this.trackingDetail = [];
      this.orderType = data.orderType;
      // if (data.orderType == "international") {
      //   let vendor = this.order.vendor;
      //   let date = this.order.date;
      axios({
        method: "GET",
        // url: `${trackingInternational}?carrier=${vendor}&waybill=${data.wbNumber}`,
        url: `${urlTracking}${data.wbNumber}`,
        headers: {
          "Content-Type": "application/json",
        },
        // data: {
        //   hawb: data.wbNumber,
        //   vendor: vendor,
        //   date: date,
        //   colly: this.order.colly,
        // },
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status == 200) {
            // console.log(res.data);
            this.loadingTrack = false;
            // this.trackingDetail = res.data;
            this.trackingDetail = res.data.result.data.history;
            // console.log(this.trackingDetail);
          } else {
            this.loadingTrack = false;
            this.snackbar = true;
            this.dialogTracking = false;
            this.snackbarText = "Tracking Tidak Di Temukan";
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadingTrack = false;
          this.snackbar = true;
          this.dialogTracking = false;
          this.snackbarText = "Tracking Tidak Di Temukan";
        });
      // } else {
      //   let vendor = this.order.vendor == "JNE" ? "JNE" : "SC";
      //   // console.log(vendor);
      //   axios({
      //     method: "GET",
      //     url: `${trackingJNE}?carrier=${vendor}&waybill=${data.wbNumber}`,
      //   })
      //     .then((res) => {
      //       if (res.data.data.history) {
      //         this.loadingTrack = false;
      //         this.trackingDetail = res.data.data.history;
      //       } else {
      //         this.loadingTrack = false;
      //         this.snackbar = true;
      //         this.dialogTracking = false;
      //         this.snackbarText = "Tracking Tidak Di Temukan";
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.loadingTrack = false;
      //       this.snackbar = true;
      //       this.dialogTracking = false;
      //       this.snackbarText = "Tracking Tidak Di Temukan";
      //     });
      // }
    },
    formatDate(date) {
      return timezone(date)
        .local("id")
        .tz("Asia/Jakarta")
        .format("DD-MM-YYYY HH:MM:SS");
    },
  },
};
</script>

<style lang="scss" scoped></style>
