<template>
  <div>
    <v-dialog v-model="dialogAddUser" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="cyan"
          class="white--text"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left> mdi-plus </v-icon>
          Add User
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add User </v-card-title>

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="No. KTP"
                    :rules="requiredRule"
                    v-model="noKtp"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                    :rules="rule.namaDepan"
                    v-model="firstName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name"
                    :rules="requiredRule"
                    v-model="lastName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    prefix="+62"
                    placeholder="83123xxxxxx"
                    label="No. Handphone"
                    :rules="requiredRule"
                    v-model="phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    type="email"
                    :rules="requiredRule"
                    v-model="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-select
                    outlined
                    dense
                    label="Group User"
                    :rules="requiredRule"
                    item-text="text"
                    item-value="value"
                    :items="userTypeList"
                    v-model="idUserType"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    disabled
                    :rules="requiredRule"
                    :items="cityList"
                    :value="city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kelurahan"
                    disabled
                    :rules="requiredRule"
                    :items="villageList"
                    :value="village"
                    @input="setVillage"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    label="Kode Pos"
                    v-model="postCode"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    outlined
                    dense
                    rows="4"
                    no-resize
                    disabled
                    label="Alamat"
                    :rules="requiredRule"
                    v-model="address"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            outlined
            @click="dialogAddUser = false"
          >
            cancel
          </v-btn>

          <v-btn
            color="cyan"
            class="white--text"
            :loading="isLoading"
            depressed
            @click="addUser"
          >
            add user
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { userTypeList } from "../../../constant/user";
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import districtData from "../../../data/kecamatan.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import { formatPhone } from "../../../utils/formatter";

export default {
  name: "DialogAddUserComponent",
  data() {
    return {
      noKtp: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      idUserType: "",
      idHub: "",
      address: "",
      city: "",
      idCity: "",
      district: "",
      idDistrict: "",
      KecamatanIDKecamatan: "",
      village: "",
      idVillage: "",
      postCode: "",
      hubList: [],
      snackbar: false,
      snackbarText: "",
      valid: false,
      dialogAddUser: false,
      isLoading: false,
      requiredRule: [(value) => !!value || "Required"],
      rule: {
        namaDepan: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 30 || "Maxsimal input 30 characters",
        ],
      },
    };
  },
  watch: {
    idHub(val) {
      if (val) {
        const idx = this.hubList.findIndex((v) => {
          return v.IDHub === val;
        });
        this.idCity = this.hubList[idx].City.IDCity;
        this.city = this.hubList[idx].City.NamaCity;
        const tempKelurahan = villageData.findIndex((v) => {
          if (
            v["kelurahan"] == this.hubList[idx].Kelurahan &&
            v["kodePos"] == this.hubList[idx].KodePost
          ) {
            return v;
          }
        });
        this.idVillage = villageData[tempKelurahan]["idKelurahan"];
        this.village = villageData[tempKelurahan]["kelurahan"];
        const tempKecamatan = districtData.findIndex((v) => {
          return v["kecamatan"] === this.hubList[idx].Kecamatan;
        });
        this.idDistrict = districtData[tempKecamatan]["idKecamatan"];
        this.postCode = this.hubList[idx].KodePost;
        this.address = this.hubList[idx].Alamat;
      }
    },
  },
  methods: {
    async addUser() {
      this.isLoading = true;
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        axios({
          method: "post",
          url: `${baseUrl}/User`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            idho: await getProfileCookie().KCX_HOIDHO,
          },
          data: {
            NamaDepan: this.firstName,
            NamaBelakang: this.lastName,
            NoKTP: this.noKtp,
            Email: this.email,
            Telp: formatPhone(this.phone),
            Alamat: this.address,
            KCX_UserTypeIDUserType: this.idUserType,
            KCX_HubIDHub: await getProfileCookie().KCX_HubIDHub,
            KelurahanIDKelurahan: this.idVillage,
            KelurahanKodePos: this.postCode,
            CityIDCity: this.idCity,
            KecamatanIDKecamatan: this.idDistrict,
          },
        })
          .then((res) => {
            this.snackbar = true;
            this.dialogAddUser = false;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.idCity = city.idCity;
        this.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.idVillage = village.idKelurahan;
        this.idDistrict = village.idKecamatan;
        this.district = e;
        this.postCode = village.kodePos;
      }
    },
    async fetchProfile() {
      const id = await getProfileCookie().IDUser;
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Profile/${id}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          this.isLoading = false;
          this.idCity = data.CityIDCity;
          this.city = data.City.NamaCity;
          this.village = data.Kelurahan.NamaKelurahan;
          this.idVillage = data.KelurahanIDKelurahan;
          this.idHub = data.KCX_HubIDHub;
          this.postCode = data.KelurahanKodePos;
          this.district = data.Kecamatan.NamaKecamatan;
          this.KecamatanIDKecamatan = data.KecamatanIDKecamatan;
          this.idDistrict = data.KecamatanIDKecamatan;
          this.address = data.Alamat;
        })
        .catch((err) => console.log(err));
    },
    async fethData() {
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          idho: await getProfileCookie().KCX_HOIDHO,
        },
      })
        .then((res) => {
          const hubData = res.data.data.filter(
            (el) => el.IDHub != 0 && el.Status == "ACTIVE"
          );

          this.hubList = hubData;
        })
        .catch(() => {
          this.hubList = [];
        });
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
    userTypeList() {
      // TODO: Only show manager HO & Operation HO for HO
      const userType = userTypeList.filter((v) => {
        return [1, 3, 4].includes(v.value);
      });
      return userType;
    },
  },
  async created() {
    // this.fethData();
    this.fetchProfile();
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
