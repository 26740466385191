<template>
  <div class="CreateOrder">
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda menambah order lain?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="done"
            :loading="isLoadingDone"
            >SELESAI</v-btn
          >
          <v-btn color="green darken-1" text @click="addMore">TAMBAH</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDfod" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda membuat order DFOD?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDfod = false"
            >BATAL</v-btn
          >

          <v-btn
            :loading="isLoadingDfod"
            color="green darken-1"
            text
            @click="book('dfod')"
            >YA</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <ShipperFormComponent />
      <br />
      <RecipientFormComponent />
      <br />
      <ItemFormComponent ref="itemDetailOrder" />
      <hr />

      <div class="CreateOrder-Summary">
        <v-container>
          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Koli</v-col>
            <v-col cols="2" class="pa-0">{{ collies }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Berat</v-col>
            <v-col cols="2" class="pa-0">{{ Math.ceil(mostWeight) }} Kg</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Biaya Packing</v-col>
            <v-col cols="2" class="pa-0" v-if="vendor == 'SENTRAL'">{{
              toRupiah(packingPrice)
            }}</v-col>
            <v-col cols="2" class="pa-0" v-else>{{ toRupiah(0) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Biaya Kirim</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(sendPrice) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Diskon</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(discount) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">PPN</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(ppn) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Asuransi</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(insurance) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0"><strong>Total Biaya</strong></v-col>
            <v-col cols="2" class="pa-0">
              <strong v-if="sendPrice > 0">{{ toRupiah(totalBiaya) }}</strong>
              <strong v-else>{{ toRupiah(0) }}</strong>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="CreateOrder-Action">
        <v-badge
          bordered
          color="error"
          :content="orderList.length"
          overlap
          class="mr-3"
          v-if="orderList.length > 0"
        >
          <v-btn
            @click="sheet = true"
            class="white--text"
            color="primary"
            depressed
          >
            List Order
          </v-btn>
        </v-badge>
        <v-btn outlined color="cyan" width="200" @click="clear">clear</v-btn>

        <v-btn
          :disabled="sendPrice > 0 ? false : true"
          :loading="isLoading"
          depressed
          color="cyan"
          d
          width="200"
          class="ml-2 white--text"
          @click="book"
          >book
        </v-btn>
        <!-- <v-btn
          v-if="dfod && citySNI == 'CGK'"
          :disabled="sendPrice > 0 ? false : true"
          :loading="isLoadingDfod"
          depressed
          color="warning"
          width="200"
          class="ml-2 white--text"
          @click="confirmDfod = true"
          >DFOD</v-btn
        > -->
      </div>
    </v-form>

    <div class="text-center">
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center" height="400px">
          <div class="text-center mt-5 pa-15" v-if="loadingListOrder">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-simple-table fixed-header height="250px" v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Shipper</th>
                  <th class="text-left">Recipient</th>
                  <th class="text-left">Koli</th>
                  <th class="text-left">Total Berat</th>
                  <th class="text-left">Destination</th>
                  <th class="text-left">Total Biaya</th>
                  <th class="text-left">DFOD</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orderList" :key="index">
                  <td class="text-left">{{ item.shipper.Nama }}</td>
                  <td class="text-left">{{ item.recipient.Nama }}</td>
                  <td class="text-left">
                    {{ sumField(item.item.collies, "JumlahKoli") }}
                  </td>
                  <td class="text-left">{{ item.item.TotalBerat }}</td>
                  <td class="text-left">{{ item.recipient.Kota }}</td>
                  <td class="text-right">
                    {{
                      item.item.TotalBiaya ? toRupiah(item.item.TotalBiaya) : 0
                    }}
                  </td>
                  <td class="text-left">
                    <span v-if="item.item.Dfod == true">
                      <v-badge
                        bordered
                        color="success"
                        content="YA"
                        overlap
                        class="mr-3"
                      />
                    </span>
                    <span v-else>
                      <v-badge
                        bordered
                        color="error"
                        content="TIDAK"
                        overlap
                        class="mr-3"
                      />
                    </span>
                  </td>

                  <td>
                    <v-btn
                      :disabled="isLoadingDelete"
                      icon
                      color="pink"
                      @click="deleteOrder(item)"
                      :loading="isLoadingDelete"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right">Total Biaya</td>
                  <td class="text-right">
                    <strong>{{ toRupiah(sumBiaya(orderList.item)) }}</strong>
                  </td>
                  <td colspan="2">&nbsp;</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-bottom-navigation absolute horizontal grow>
            <v-btn
              color="deep-red accent-4"
              text
              elevation="2"
              rounded
              @click="sheet = false"
            >
              <span>Close</span>

              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              color="deep-info accent-4"
              text
              elevation="2"
              rounded
              :loading="isLoadingDone"
              @click="done"
            >
              <span>Buat Order</span>
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ShipperFormComponent from "@/components/dashboard/inbound/ShipperFormComponent.vue";
import RecipientFormComponent from "@/components/dashboard/inbound/RecipientFormComponent.vue";
import ItemFormComponent from "@/components/dashboard/inbound/ItemFormComponent.vue";

import { formatToRupiah } from "../../../utils/formatter";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
// import { getProfileCookie } from "../../../utils/cookies";
import {
  INSURANCE_RATE,
  INSURANCE_RATE_SENTRAL,
  INSURANCE_ADDITIONAL_COST,
  INSURANCE_ADDITIONAL_COST_SENTRAL,
} from "../../../constant/insurance";

export default {
  name: "CreateOrder",
  components: {
    ShipperFormComponent,
    RecipientFormComponent,
    ItemFormComponent,
  },
  mounted() {
    this.fetchTempOrder();
    this.fetchBalance();
  },
  data() {
    return {
      sheet: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      isLoadingDfod: false,
      isLoadingDelete: false,
      isLoadingDone: false,
      showDialog: false,
      confirmDfod: false,
      citySNI: getProfileCookie().CitySNI,
      orderList: [],
      loadingListOrder: false,
      balance: 0,
      colorSnackbar: "cyan",
    };
  },
  methods: {
    async fetchBalance() {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/BalanceHub/${getProfileCookie().KCX_HubIDHub}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.balance = res.data.data.balance;
    },
    fetchTempOrder() {
      this.loadingListOrder = true;
      this.orderList = [];
      axios({
        url: `${baseUrl}/HubInboundOrders/tmpOrder/${
          getProfileCookie().KCX_HubIDHub
        }/domestic`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          let data = res.data.data;
          data.map((item) => {
            let dataOrder = {
              ...JSON.parse(item.DataOrder),
              OrderModule: item.OrderModule,
              IDTempOrder: item.IDTempOrder,
              BiayaKirim: item.BiayaKirim,
              TotalBiaya: item.TotalBiaya,
              KomisiHO: item.KomisiHO,
              KomisiHub: item.KomisiHub,
              TotalBiayaHub: item.TotalBiayaHub,
            };
            this.orderList.push(dataOrder);
          });
        })
        .catch((err) => {
          console.log(err.response.data.message.error[0]);
        })
        .finally(() => {
          this.loadingListOrder = false;
        });
    },
    book(type) {
      this.valid = this.$refs.form.validate();
      const hubId = getProfileCookie().KCX_HubIDHub;
      if (!this.valid) {
        this.snackbar = true;
        this.snackbarText = "Mohon periksa data anda kembali!";
      }
      if (this.valid) {
        //set order type
        this.$store.commit("order/SET_ITEM", {
          orderType: "domestic",
        });
        if (type == "dfod") {
          this.isLoadingDfod = true;
        } else {
          this.isLoading = true;
        }
        // let endPoint = "";

        // const isWalkin = this.$store.state.order.isWalkin;
        const idShipper = this.$store.state.order.idShipper;
        const idRecipient = this.$store.state.order.idRecipient;
        const idCollies = this.$store.state.order.idCollies;
        const idOrder = this.$store.state.order.idOrder;
        const shipper = this.$store.state.order.shipperData;
        const recipient = this.$store.state.order.recipientData;
        const item = this.$store.state.order.itemData;

        // if (isWalkin) {
        //   endPoint = "HubOrders";
        // } else {
        //   endPoint = "BookOrder";
        // }

        //save to repeat order order
        const orderModule = {
          shipperData: shipper,
          recipientData: recipient,
          itemData: item,
        };

        const insurance = this.insurance;
        const isDfod = type == "dfod" ? true : false;
        let orderItem = {
          hubId,
          idOrder,
          idShipper,
          idRecipient,
          idCollies,
          typeOrder: "domestic",
          shipper: {
            Nama: shipper.shipperName,
            Telp: shipper.shipperPhone,
            Email: shipper.shipperEmail,
            Alamat: shipper.shipperAddress,
            IDCity: shipper.shipperCityId,
            NamaCity: shipper.shipperCity,
            NamaKecamatan: shipper.shipperKecamatan,
            NamaKelurahan: shipper.shipperKelurahan,
            KodePos: shipper.shipperPostCode,
            IDKecamatan: shipper.shipperKecamatanId,
            IDKelurahan: shipper.shipperKelurahanId,
            IDHub: shipper.shipperIDHub,
            NewShipper: shipper.newShipper,
          },
          recipient: {
            Nama: recipient.recipientName,
            Telp: recipient.recipientPhone,
            Email: recipient.recipientEmail,
            Kota: recipient.recipientCity,
            Alamat: recipient.recipientAddress,
            CityIDCity: recipient.recipientCityId,
            KelurahanIDKelurahan: recipient.recipientKelurahanId,
            KelurahanKodePos: recipient.recipientPostCode,
            IDHub: recipient.recipientIDHub,
            NewRecipient: recipient.newRecipient,
            RecipientNamaKelurahan: recipient.recipientNamaKelurahan,
            RecipientKelurahan: recipient.recipientKelurahan,
            RecipientKecamatanId: recipient.recipientKecamatanId,
            RecipientNamaKecamatan: recipient.recipientNamaKecamatan,
          },
          item: {
            Moda: item.moda.toUpperCase(),
            IDVia: item.idvia,
            Keterangan: item.description,
            Instruction: item.instruction,
            Diskon: item.discount,
            // PPN: item.tax,
            PPN: this.ppn,
            EstimasiNilai: item.estimateValue,
            Asuransi: insurance,
            TotalKoli: this.collies,
            TotalBerat: Math.ceil(this.mostWeight),
            TotalBiayaPacking: this.packingPrice,
            BiayaKirim: this.sendPrice,
            TotalBiaya: Math.round(this.totalPrice + this.insurance + this.ppn),
            IsInsurance: item.isInsurance,
            collies: item.collies.map((el) => {
              return {
                JumlahKoli: el.numberColly,
                Berat: el.weight,
                Panjang: el.length,
                Lebar: el.width,
                Tinggi: el.height,
                BeratVolume: el.volumeWeight,
                Terberat: el.mostWeight,
                IsPacking: el.isPacking,
                BiayaPacking: item.vendor == "JNE" ? 0 : el.packingPrice,
                TotalBiaya: item.vendor == "JNE" ? 0 : el.packingPrice,
                DescriptionItem: el.descriptionItem,
              };
            }),
            Kategory: item.packageCategory,
            Vendor: item.vendor,
            Dfod: isDfod,
            Etd: item.etd,
            Rate3PL: item.rate3PL,
          },
        };
        // console.log(orderItem);

        // this.orderList.push(orderItem);
        //create List Order
        axios({
          url: `${baseUrl}/HubInboundOrders/tmpOrder`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            hub: hubId,
          },
          data: {
            Order: orderItem,
            OrderModule: orderModule,
            IDVia: item.idvia,
          },
        })
          .then(() => {
            this.showDialog = true;
            this.isLoading = false;
            this.isLoadingDfod = false;
            this.snackbar = true;
            this.colorSnackbar = "green";
            this.snackbarText = "Order Saved";
            this.fetchTempOrder();
          })
          .catch((err) => {
            this.isLoading = false;
            this.isLoadingDfod = false;
            this.snackbar = true;
            this.colorSnackbar = "red";
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    clear() {
      this.$store.commit("order/CLEAR_DATA");
      this.valid = this.$refs.form.resetValidation();
      this.showDialog = false;
      window.scrollTo({ top: 0 });
      window.location.reload();
    },
    done() {
      // console.log(this.orderList);
      this.isLoadingDone = true;
      //** Cek Balance Than Total */
      if (Number(this.balance) < Number(this.sumBiaya())) {
        this.isLoadingDone = false;
        this.colorSnackbar = "red";
        this.snackbar = true;
        this.snackbarText = "Saldo Tidak Mencukupi";
        this.showDialog = false;
        this.$store.commit("order/CLEAR_DATA");
        return;
      }
      const hubId = getProfileCookie().KCX_HubIDHub;
      const hoid = getProfileCookie().KCX_HOIDHO;
      axios({
        url: `${baseUrl}/HubOrders`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
          ho: hoid,
        },
        data: {
          Order: this.orderList,
          totalBiaya: this.sumBiayaKirim(),
          komisiHO: this.sumKomisiHO(),
          komisiHub: this.sumKomisiHub(),
          totalBiayaHub: this.sumTotalBiayaHub(),
        },
      })
        .then((res) => {
          this.isLoadingDone = false;
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          // this.clear();
          this.$store.commit("order/CLEAR_DATA");
          this.valid = this.$refs.form.resetValidation();
          this.showDialog = false;
          this.isLoadingDfod = true;
          this.$router.push("/dashboard/order-confirmation");
        })
        .catch((err) => {
          this.isLoadingDone = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
          this.showDialog = false;
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
    calculatedWeightToPrice(price) {
      const perKiloPrice = price / 10;
      if (this.$store.state.order.itemData.totalWeight > 10) {
        return this.$store.state.order.itemData.totalWeight * perKiloPrice;
      }
      return this.$store.state.order.itemData.totalWeight * perKiloPrice;
    },
    addMore() {
      this.$store.commit("order/CLEAR_RECIPIENT_INFORMATION");
      this.showDialog = false;
      window.scrollTo({ top: 0 });
      this.$refs.itemDetailOrder.listPrice = [];
      this.$store.commit("order/SET_RECIPIENT", {
        recipientIDHub: getProfileCookie().KCX_HubIDHub,
      });
    },
    sumField(koli, key) {
      return koli.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumBiaya() {
      return this.orderList.reduce(
        (a, b) => a + (b["item"]["TotalBiaya"] || 0),
        0
      );
    },
    sumBiayaKirim() {
      return this.orderList.reduce(
        (a, b) => a + (b["item"]["BiayaKirim"] || 0),
        0
      );
    },
    sumKomisiHub() {
      return this.orderList.reduce((a, b) => a + (b["KomisiHub"] || 0), 0);
    },
    sumKomisiHO() {
      return this.orderList.reduce((a, b) => a + (b["KomisiHO"] || 0), 0);
    },
    sumTotalBiayaHub() {
      return this.orderList.reduce((a, b) => a + (b["TotalBiayaHub"] || 0), 0);
    },
    async deleteOrder(item) {
      this.isLoadingDelete = true;
      axios({
        url: `${baseUrl}/HubInboundOrders/tmpOrder/delete/${item.IDTempOrder}`,
        method: "delete",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then(() => {
          this.snackbar = true;
          this.isLoadingDelete = false;
          this.snackbarText = "Order Deleted";
          this.fetchTempOrder();
          if (this.orderList < 1) {
            this.sheet = false;
          }
        })
        .catch((err) => {
          this.isLoadingDelete = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
  computed: {
    collies() {
      const collies = this.$store.state.order.itemData.collies;

      if (collies.length != 0) {
        return collies.reduce((acc, obj) => {
          return acc + obj.numberColly;
        }, 0);
      }
      return 0;
    },
    mostWeight() {
      // return this.$store.state.order.itemData.totalWeight;
      const collies = this.$store.state.order.itemData.collies;
      if (collies.length != 0) {
        let totWeight = collies.reduce((acc, obj) => {
          return acc + obj.mostWeight * obj.numberColly;
        }, 0);
        if (totWeight < 5) {
          return 5;
        } else {
          return totWeight;
        }
      }
      return 0;
    },
    packingPrice() {
      const collies = this.$store.state.order.itemData.collies;
      const vendor = this.$store.state.order.itemData.vendor;
      if (vendor == "JNE") {
        return 0;
      } else {
        if (collies.length != 0) {
          return collies.reduce((acc, obj) => {
            return acc + obj.packingPrice;
          }, 0);
        }
      }
      return 0;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
    vendor() {
      return this.$store.state.order.itemData.vendor;
    },
    insurance() {
      if (this.$store.state.order.itemData.isInsurance) {
        if (this.$store.state.order.itemData.vendor == "JNE") {
          const estimateValue = parseFloat(
            this.$store.state.order.itemData.estimateValue
          );
          return Math.ceil(
            estimateValue * INSURANCE_RATE + INSURANCE_ADDITIONAL_COST
          );
        } else if (this.$store.state.order.itemData.vendor == "SENTRAL") {
          const estimateValue = parseFloat(
            this.$store.state.order.itemData.estimateValue
          );
          let totIns =
            estimateValue * INSURANCE_RATE_SENTRAL +
            INSURANCE_ADDITIONAL_COST_SENTRAL;
          return totIns > 5000 ? Math.ceil(totIns) : 5000;
        }
      }
      return 0;
      // return this.$store.state.order.itemData.insurance;
    },
    discount() {
      return Math.round(this.$store.state.order.itemData.discount);
    },
    ppn() {
      // const total = this.packingPrice + this.sendPrice - this.discount;
      // return Math.ceil(total * 0.011);
      return 0;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    totalPrice() {
      const total = this.packingPrice + this.sendPrice - this.discount;
      return total;
    },
    totalBiaya() {
      if (this.totalPrice > 0)
        return Math.ceil(this.totalPrice + this.insurance + this.ppn);
      return 0;
    },
    dfod() {
      return this.$store.state.order.itemData.dfod;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateOrder {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px;
  }

  &-Action {
    text-align: right;
    padding: 16px;
  }

  &-Summary {
    text-align: right;
  }
}
</style>
