<template>
  <div class="ItemFormComponent">
    <div class="ItemFormComponent-Header">
      <h3>Package Information</h3>
      <hr />
    </div>

    <div class="ItemFormComponent-Container">
      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Via Moda</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <small style="color: red" v-if="moda == 'Udara'"
              >Pengiriman Udara Di atas 50kg akan dikenakan
              <strong>Surcharge</strong>. (Total Berat x 2)</small
            >
            <v-select
              :readonly="this.collyDetail.length > 0 ? true : false"
              outlined
              dense
              v-model="viaModa"
              :value="moda"
              @input="setModa"
              :items="via"
              item-text="Keterangan"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Tipe Paket</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <v-select
              :readonly="this.collyDetail.length > 0 ? true : false"
              :rules="rules"
              outlined
              dense
              :value="packageCategory"
              @input="setElectronic"
              :items="electronicOption"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Total Koli</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :value="sumField('numberColly')"
              disabled
              outlined
              dense
              :rules="positiveNumberRule"
            ></v-text-field>

            <v-row>
              <v-col cols="6" class="">
                <dialog-add-item-component
                  :packageCategory="packageCategory ? false : true"
                  :resetListPrice="resetListPrice"
                />
              </v-col>
              <v-col cols="6" class="">
                <dialog-bulk-colly-component
                  :packageCategory="packageCategory ? false : true"
                  :resetListPrice="resetListPrice"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Keterangan</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rule.descriptionRule"
              outlined
              rows="2"
              no-resize
              :value="description"
              @input="setDescription"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Instruksi Khusus</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rule.instructionRule"
              outlined
              rows="2"
              no-resize
              :value="instruction"
              @input="setInstruction"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Diskon</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              disabled
              :value="discount"
              @input="setDiscount"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="4" class="pa-0"> <h4>PPN</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              :value="tax"
              @input="setTax"
            ></v-text-field>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Nilai Barang</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              :value="estimateValue"
              @input="setEstimateValue"
              :rules="isInsurance ? estimateValueRule : undefined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0 ma-0">
            <v-checkbox
              label="Asuransi"
              v-model="checkInsurance"
              @change="setInsurance"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-row>
      <v-col cols="12">
        <div class="ItemFormComponent-Result" v-if="collyDetail.length > 0">
          <v-col class="text-right">
            <v-btn @click="clearColly" color="error" elevation="2" small
              >Clear All Colly</v-btn
            >
          </v-col>
          <v-data-table :headers="headers" :items="collyDetail">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                depressed
                class="mr-2"
                small
                color="red"
                @click="deleteItem(item)"
              >
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.mostWeight * item.numberColly }}
            </template>
            <template v-slot:[`item.packing`]="{ item, index }">
              <v-checkbox
                color="indigo"
                value="indigo"
                @click="packing(item, index)"
              ></v-checkbox>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="9">
            <table
              class="tot"
              v-if="this.collyDetail.length > 0"
              v-show="showCBM"
            >
              <tr>
                <td>Jumlah Item</td>
                <td>Jumlah Kg</td>
                <td>Jumlah Kg Final</td>
                <td>Jumlah CBM</td>
                <td>Jumlah Koli</td>
              </tr>
              <tr>
                <th>{{ this.collyDetail.length }}</th>
                <th>{{ grossWeight(this.collyDetail) }}</th>
                <th>{{ kgFinal(this.collyDetail) }}</th>
                <th>{{ (cbm(this.collyDetail) / 1000000).toFixed(2) }}</th>
                <th>{{ sumField("numberColly") }}</th>
              </tr>
            </table>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="mt-5 mr-5"
              @click="getPrice()"
              color="primary"
              :disabled="
                this.$store.state.order.recipientData.recipientPostCode == '' ||
                this.collyDetail.length < 1
                  ? true
                  : false
              "
            >
              <v-progress-circular
                indeterminate
                color="white"
                v-if="loadingCheck"
              ></v-progress-circular
              ><span v-else>Check Price</span></v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6">
        <v-item-group mandatory v-if="listPrice.length > 0">
          Price List :
          <v-container>
            <v-row>
              <v-col v-for="(n, i) in listPrice" :key="i" cols="12" md="12">
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? '#4eccd4' : ''"
                    class="d-flex align-center"
                    height="70"
                    @click="setPrice(n, toggle)"
                  >
                    <v-scroll-y-transition>
                      <div class="flex-grow-1 text-center">
                        <v-row>
                          <v-col cols="4">
                            Vendor: {{ i + 1 }} <br />
                            <i>{{ n.description }}</i>
                          </v-col>
                          <v-col cols="4"
                            >Harga: {{ convertToRp(n.price) }} <br /><i
                              ><small>{{ packageCategory }}</small></i
                            ></v-col
                          >
                          <v-col cols="4"
                            >Estimasi Pengiriman: {{ n.etd }}</v-col
                          >
                        </v-row>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-col>
    </v-row>
    <v-snackbar timeout="2000" v-model="snackbar" top color="red">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import DialogAddItemComponent from "./DialogAddItemComponent.vue";
import DialogBulkCollyComponent from "./DialogBulkCollyComponent.vue";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";
import {
  calculateVolumetricWeight,
  calculateVolumetricWeightJTR,
  calculateVolumetricWeightUdara,
} from "../../../utils/calculation";
import {
  PACKING_WOOD_SENTRAL,
  ADDITIONAL_LENGTH,
  ADDITIONAL_WIDTH,
  ADDITIONAL_HEIGHT,
} from "../../../constant/insurance";
import sentralOrigin from "../../../data/originSentral.json";
import originCode from "../../../data/originCode.json";
import { mapState, mapActions } from "vuex";

export default {
  name: "ItemFormComponent",
  components: { DialogAddItemComponent, DialogBulkCollyComponent },
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      rule: {
        descriptionRule: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 60 || "Maxsimal input 60 characters",
        ],
        instructionRule: [
          (v) => (v || "").length <= 60 || "Maxsimal input 60 characters",
        ],
      },
      estimateValueRule: [
        (value) => value > 0 || "Harus di Isi jika Asuransi Aktif",
        (value) => !!value || "Required",
      ],
      positiveNumberRule: [(value) => value > 0 || "Add item"],
      modaOption: [
        { text: "Darat / Combo", value: "Darat" },
        { text: "Udara", value: "Udara" },
        { text: "Laut", value: "Laut" },
      ],
      electronicOption: [
        { text: "Pilih Tipe Paket", value: "" },
        { text: "NON ELEKTRONIK", value: "NON ELEKTRONIK" },
        { text: "ELEKTRONIK", value: "ELEKTRONIK" },
      ],
      headers: [
        {
          text: "Desc. Item",
          value: "descriptionItem",
          sortable: false,
          filterable: false,
        },
        { text: "Koli", value: "numberColly" },
        { text: "Panjang", value: "length" },
        { text: "Lebar", value: "width", filterable: false },
        { text: "Tinggi", value: "height", filterable: false },
        { text: "Berat Item (kg)", value: "weight", filterable: false },
        { text: "Berat Volume (kg)", value: "volumeWeight", filterable: false },
        // { text: "Biaya Packing", value: "packingPrice", filterable: false },
        { text: "Terberat (kg)", value: "mostWeight", filterable: false },
        { text: "Total Berat (kg)", value: "total", filterable: false },
        { text: "Packing Kayu", value: "packing", filterable: false },
        // { text: "Total Biaya", value: "totalPrice", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      listPrice: [],
      finalWeight: 0,
      loadingCheck: false,
      checkInsurance: true,
      snackbar: false,
      snackbarText: "",
      showCBM: false,
      viaModa: null,
    };
  },
  async mounted() {
    await this.getVia("domestic");
    if (this.idvia) {
      let idvia = this.via.find((el) => el.IDVia == this.idvia);
      this.viaModa = idvia;
      // console.log(this.via);
    }
  },
  computed: {
    ...mapState({
      via: (state) => state.masterData.via,
    }),
    moda() {
      return this.$store.state.order.itemData.moda;
    },
    idvia() {
      return this.$store.state.order.itemData.idvia;
    },
    description() {
      return this.$store.state.order.itemData.description;
    },
    instruction() {
      return this.$store.state.order.itemData.instruction;
    },
    discount() {
      return this.$store.state.order.itemData.discount;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    estimateValue() {
      return this.$store.state.order.itemData.estimateValue;
    },
    isInsurance() {
      return this.$store.state.order.itemData.isInsurance;
    },
    packageCategory() {
      return this.$store.state.order.itemData.packageCategory;
    },
    totalColly() {
      const collies = this.$store.state.order.itemData.collies;
      return collies.length;
    },
    collyDetail() {
      return this.$store.state.order.itemData.collies;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
  },
  methods: {
    ...mapActions({
      getVia: "masterData/getVia",
    }),
    setPrice(data, toggle) {
      this.showCBM = true;

      this.$store.commit("order/SET_ITEM", {
        kargoPrice: data.price,
        rate3PL: data.rate,
        isInsurance: this.isInsurance,
        vendor: data.vendor,
        totalWeight: Number(this.finalWeight),
        dfod: data.dfod,
        etd: data.etd,
      });
      let newCollies = this.collyDetail.map((item) => {
        let volumeWeight, mostWeight;
        const weight = Number(item.weight);

        if (this.moda == "Darat") {
          if (data.vendor == "JNE") {
            volumeWeight = calculateVolumetricWeightJTR(
              item.length,
              item.width,
              item.height
            );
          } else {
            volumeWeight = calculateVolumetricWeight(
              item.length,
              item.width,
              item.height
            );
          }

          mostWeight = volumeWeight > weight ? volumeWeight : weight;
        } else {
          volumeWeight = calculateVolumetricWeightUdara(
            item.length,
            item.width,
            item.height
          );
          let isSurcharge = volumeWeight > weight ? volumeWeight : weight;
          mostWeight = isSurcharge > 50 ? isSurcharge * 2 : isSurcharge;
        }

        return {
          numberColly: Number(item.numberColly),
          weight,
          length: Number(item.length),
          width: Number(item.width),
          height: Number(item.height),
          volumeWeight,
          isPacking: item.isPacking,
          packingPrice: item.packingPrice,
          mostWeight,
          totalPrice: item.totalPrice,
          descriptionItem: item.descriptionItem,
        };
      });
      this.$store.commit("order/SET_ITEM", { collies: [...newCollies] });
      toggle();
    },
    setModa(e) {
      // console.log(e);
      this.$store.commit("order/SET_ITEM", { moda: e.Via });
      this.$store.commit("order/SET_ITEM", { idvia: e.IDVia });
    },
    setDescription(e) {
      this.$store.commit("order/SET_ITEM", { description: e });
    },
    setInstruction(e) {
      this.$store.commit("order/SET_ITEM", { instruction: e });
    },
    setDiscount(e) {
      this.$store.commit("order/SET_ITEM", { discount: e });
    },
    setTax(e) {
      this.$store.commit("order/SET_ITEM", { tax: e });
    },
    setEstimateValue(e) {
      this.$store.commit("order/SET_ITEM", { estimateValue: e });
    },
    setInsurance() {
      this.$store.commit("order/SET_ITEM", {
        isInsurance: this.checkInsurance,
      });
    },
    setElectronic(e) {
      this.listPrice = [];
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });
      this.$store.commit("order/SET_ITEM", { packageCategory: e });
    },
    async deleteItem(e) {
      const collies = [...this.$store.state.order.itemData.collies];

      const index = collies.findIndex((el) => {
        return el == e;
      });

      collies.splice(index, 1);
      let totalWeight;
      if (collies.length == 0) {
        totalWeight = 0;
      } else {
        totalWeight = this.kgFinal(collies);
      }

      this.$store.commit("order/SET_ITEM", { totalWeight });
      this.$store.commit("order/SET_ITEM", { collies });

      if (totalWeight != 0) {
        await this.getPrice();
      }

      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });
      this.listPrice = [];
      this.showCBM = false;
    },
    sumField(key) {
      return this.collyDetail.reduce((a, b) => a + (b[key] || 0), 0);
    },
    grossWeight(item) {
      let grosslKg = item.reduce((a, b) => a + b.weight * b.numberColly, 0);
      return Math.ceil(grosslKg);
    },
    kgFinal(item) {
      let finalKg = item.reduce((a, b) => a + b.mostWeight * b.numberColly, 0);
      this.finalWeight = finalKg < 5 ? 5 : finalKg;
      return Math.ceil(this.finalWeight);
    },
    cbm(item) {
      return item.reduce(
        (a, b) => a + b.length * b.width * b.height * b.numberColly,
        0
      );
    },
    getPrice() {
      if (this.packageCategory != "") {
        const profile = getProfileCookie();
        const orgCity =
          this.$store.state.order.shipperData.shipperCity.search("Jakarta") > 0
            ? "Jakarta"
            : this.$store.state.order.shipperData.shipperCity
                .replace("Kabupaten", "")
                .replace("Kota", "")
                .replace("Selatan", "")
                .replace("Timur", "")
                .replace("Utara", "")
                .replace("Barat", "")
                .replace("Tenggara", "")
                .replace("Daya", "")
                .replace("Tengah", "");

        const originSentral = sentralOrigin.find(
          ({ OriginName }) =>
            OriginName.toLowerCase().trim() == orgCity.toLowerCase().trim()
        );

        // console.log(originSentral);
        const orgnJne =
          this.$store.state.order.shipperData.shipperCity.search("Jakarta") > 0
            ? "Jakarta"
            : this.$store.state.order.shipperData.shipperCity
                .replace("Kabupaten", "")
                .replace("Kota", "");
        //filter
        const orgnCode = originCode.filter((object) =>
          Object.values(object).some((i) =>
            i.toLowerCase().includes(orgnJne.toLowerCase().trim())
          )
        );
        let codeOrigin = profile.OriginCode ? profile.OriginCode : "10000";

        //set dest kab sentral
        let splitDest =
          this.$store.state.order.recipientData.recipientCity.split(",");
        const destSC =
          splitDest[0].search("Jakarta") > 0
            ? "Jakarta"
            : splitDest[0].replace("Kabupaten", "").replace("Kota", "");

        const splitKec =
          this.$store.state.order.recipientData.recipientKelurahan.split(",");

        this.listPrice = [];
        this.loadingCheck = true;
        axios({
          url: `${baseUrl}/CheckPrice`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Length: 0,
            Height: 0,
            Width: 0,
            Weight: Number(this.finalWeight),
            VillageName:
              this.$store.state.order.recipientData.recipientNamaKelurahan
                .trim()
                .toLowerCase(),
            CityName: originSentral ? originSentral.OriginDestCode : "",
            Moda: this.moda.toLowerCase(),
            KodePosDestination:
              this.$store.state.order.recipientData.recipientPostCode.toString(),
            PackageCategory: this.packageCategory,
            CitySNI: profile.CitySNI,
            OriginCode:
              orgnCode.length > 0
                ? orgnCode[0].originCode
                : `${profile.CitySNI}${codeOrigin}`,
            DestKecamatan: splitKec[1],
            KabupatenName: destSC,
            DetailKoli: this.collyDetail,
          },
        })
          .then((res) => {
            this.listPrice = res.data.data.price;
            this.listPrice.sort(
              (a, b) => parseFloat(a.price) - parseFloat(b.price)
            );
            this.loadingCheck = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
            this.loadingCheck = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarText = "Kategory Paket Harus di Pilih";
        this.loadingCheck = false;
      }
    },
    convertToRp(bil) {
      return formatToRupiah(bil);
    },
    async packing(item, i) {
      this.collyDetail[i].isPacking = !item.isPacking;
      if (this.collyDetail[i].isPacking === true) {
        let rp = PACKING_WOOD_SENTRAL;
        let width = Number(item.width) + ADDITIONAL_WIDTH;
        let height = Number(item.height) + ADDITIONAL_HEIGHT;
        let length = Number(item.length) + ADDITIONAL_LENGTH;

        let p = ((width + height + length) / 3) * rp;
        this.collyDetail[i].packingPrice = Math.ceil(p);
        //update weight
        this.collyDetail[i].height = height;
        this.collyDetail[i].width = width;
        this.collyDetail[i].length = length;
        let volumeWeight, mostWeight;
        if (this.moda == "Darat") {
          volumeWeight = calculateVolumetricWeight(width, height, length);
          mostWeight =
            volumeWeight > this.collyDetail[i].weight
              ? volumeWeight
              : this.collyDetail[i].weight;
        } else {
          volumeWeight = calculateVolumetricWeightUdara(width, height, length);
          let isSurcharge =
            volumeWeight > this.collyDetail[i].weight
              ? volumeWeight
              : this.collyDetail[i].weight;
          mostWeight = isSurcharge > 50 ? isSurcharge * 2 : isSurcharge;
        }

        this.collyDetail[i].mostWeight = Math.ceil(mostWeight);
        this.collyDetail[i].volumeWeight = Math.ceil(volumeWeight);
      } else {
        let width = Number(item.width) - ADDITIONAL_WIDTH;
        let height = Number(item.height) - ADDITIONAL_HEIGHT;
        let length = Number(item.length) - ADDITIONAL_LENGTH;

        this.collyDetail[i].packingPrice = 0;
        this.collyDetail[i].height = height;
        this.collyDetail[i].width = width;
        this.collyDetail[i].length = length;
        let volumeWeight, mostWeight;
        if (this.moda == "Darat") {
          volumeWeight = calculateVolumetricWeight(width, height, length);
          mostWeight =
            volumeWeight > this.collyDetail[i].weight
              ? volumeWeight
              : this.collyDetail[i].weight;
        } else {
          volumeWeight = calculateVolumetricWeightUdara(width, height, length);
          let isSurcharge =
            volumeWeight > this.collyDetail[i].weight
              ? volumeWeight
              : this.collyDetail[i].weight;
          mostWeight = isSurcharge > 50 ? isSurcharge * 2 : isSurcharge;
        }

        this.collyDetail[i].mostWeight = Math.ceil(mostWeight);
        this.collyDetail[i].volumeWeight = Math.ceil(volumeWeight);
      }
      this.kgFinal(this.collyDetail);
      await this.getPrice();
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });
    },
    resetListPrice() {
      this.listPrice = [];
    },
    clearColly() {
      this.$store.commit("order/SET_ITEM", { collies: [] });
      this.listPrice = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.ItemFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
.tot td {
  padding: 10px;
  text-align: center;
}
</style>
